import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import ProductPromotionGridContainer from '../../containers/ProductPromotionGrid/ProductPromotionGridContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../../containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import PromotionsAggregatedByVendorsWidgetContainer
	from '../../containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByStateType } from '../../types/IPromotionsAggregatedByStateType';
import { IPromotionsAggregatedByVendorType } from '../../types/IPromotionsAggregatedByVendorType';
import StoryListWidgetContainer from '../../../Story/containers/StoryListWidget/StoryListWidgetContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';

class VoucherPage extends React.Component<IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs () {
		return [
			{
				label: 'Gutscheine',
			} as IBreadcrumbItemType,
		];
	}

	public render() {
		const { routeMetadata } = this.props;

		if (!routeMetadata) {
			return null;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<H1>
					{routeMetadata.pageTitle}
				</H1>
				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>
							<ProductPromotionGridContainer
								limit={40}
								enablePagination={true}
								fetchVoucherProductPromotions={true}
								orderBy="createdAt"
								orderDir="desc"
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>
							
							<PromotionsAggregatedByVendorsWidgetContainer
								title="Gutscheine nach Händler"
								widgetKey={`${routeMetadata.queryKey}-vendor-widget`}
								limit={10}
								fetchVoucherProductPromotions={true}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
								routeCallbk={this.routerCallbkVendorListWidget}
								
							/>

							<PromotionsAggregatedByStatesWidgetContainer
								title="Gutscheine nach Bundesland"
								widgetKey={`${routeMetadata.queryKey}-state-widget`}
								fetchVoucherProductPromotions={true}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
								routeCallbk={this.routerCallbkStateListWidget}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>


							<StoryListWidgetContainer
								title='Beliebte Stories'
								widgetKey={`${routeMetadata.queryKey}-stories`}
								moreLabel="Alle Stories"
								limit={3}
							/>

							<MobileAppWidget />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStateListWidget = (item: IPromotionsAggregatedByStateType) => {
		return {
			name: 'VouchersInStateRoute',
			params: {
				state: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {
		return {
			name: 'VouchersByVendorRoute',
			params: {
				vendor: item.group.slug,
			},
		};
	}
}

export default VoucherPage;
