import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import DataLayerService from '../../../Base/services/DataLayerService';
import * as styles from './PromotionworldDetail.scss';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { Helmet } from 'react-helmet-async';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import { IPromotionworldType } from '../../types/IPromotionworldType';
import FilterChipsListing from '../../../Tag/components/FilterChipsListing/FilterChipsListing';
import { IFilterChip } from '../../../Tag/types/IFilterChip';
import AdItem from '../../../../components/General/AdItem/AdItem';
import PromotionworldHeader from './PromotionworldHeader';
import PromotionworldFooter from './PromotionworldFooter';
import PromotionworldGridGroupedContainer from '../../containers/PromotionworldGridGrouped/PromotionworldGridGroupedContainer';
import withLeafletList from '../../../Leaflet/hocs/withLeafletListing';
import PromotionworldDetailFurtherLeaflets from './PromotionworldDetailFurtherLeaflets';
import Icon from '../../../Base/components/Icon/Icon';
import { trackPromotionClickout } from '../../../Promotion/utils/trackPromotionClickout';

interface IPromotionworldDetailProps {
	queryKey: string;
	promotionworld: IPromotionworldType;
}

interface IPromotionworldDetailrStatesType {
	activeTag: IFilterChip;
}

const tracked: { [promotionworldId: string]: boolean } = {};

class PromotionworldDetail extends React.Component<IPromotionworldDetailProps & IWithRouteMetadataType, IPromotionworldDetailrStatesType> {
	public static defaultProps = {};

	constructor(props: IPromotionworldDetailProps) {
		super(props);
		this.state = {
			activeTag: null,
		};
	}

	public componentDidMount() {
		this.trackpromotionworldView();
	}

	// TRANSLATE
	public render() {
		const { promotionworld, queryKey } = this.props;
		const { activeTag } = this.state;

		return (
			<React.Fragment>

				{(!promotionworld) ? null :
					<Helmet encodeSpecialCharacters={false}>
						<meta name="og:title" content={promotionworld?.leaflet?.title} />
						{isBrowser() && <meta name="og:description" content={document.querySelector('meta[name="description"]')?.content} />}
						<meta name="og:type" content="website" />
						<meta name="og:url" content={`https://www.aktionsfinder.at/tw/${promotionworld.leaflet.slug}`} />
						<meta name="og:image" content={promotionworld.leaflet.leafletTeaserImageResolutions?.small} />
					</Helmet>
				}

				<PromotionworldHeader promotionworld={promotionworld} />

				<div className={styles.promotionworldDetailContainer}>
					<div className={`${styles.promotionworldWrapper}`}>

						<div className={styles.contentWrapper}>
							{(!promotionworld || !promotionworld.teaserImage) ? null :
								<div>
									{(promotionworld.promotionWorldClickoutUrl) ?
										<a 
											className={`${styles.headerImage} ${styles.headerImageLinked}`} 
											href={promotionworld.promotionWorldClickoutUrl} 
											onClick={this.trackHeaderImageClickout}
											target="_blank" 
											rel="nofollow"
										>
											<ImageEnhanced
												source={promotionworld.teaserImage.resolutions}
											/>

											<Icon icon="hotspot-link" className={styles.headerImageClickout} />
										</a>
										:
										<div className={styles.headerImage}>
											<ImageEnhanced
												source={promotionworld.teaserImage.resolutions}
											/>
										</div>
									}
								</div>
							}

							<div className={styles.promotionsContainer}>

								<FilterChipsListing
									tags={promotionworld.promotionWorldGroups}
									activeTag={activeTag}
									onActiveTagChange={this.changeActiveTag}
									allLabel="Alle"
								/>

								<PromotionworldGridGroupedContainer
									promotionworld={promotionworld}
									gridKey={queryKey}
									moreLabel="Mehr laden ..."
									activeTag={activeTag}
									promotionCount="12"
									onPromotionClick={this.trackPromotionClick}
								/>

							</div>
						</div>

						{withLeafletList(
							PromotionworldDetailFurtherLeaflets,
							Object.assign({}, this.props, {
								referenceSlug: promotionworld.leaflet.slug,
								onlyNearbyLeaflets: true,
								limit: 40,
								orderBy: 'rank',
								orderDir: 'desc',
							})
						)}

						<div className={styles.promotionworldFooterDisclaimer}>
							<div dangerouslySetInnerHTML={{ __html: promotionworld.disclaimer }} />
						</div>

					</div>
				</div>

				{withLeafletList(
					PromotionworldFooter,
					Object.assign({},
						{
							...this.props,
							pageQueryKey: queryKey,
							onActiveTagChange: this.changeActiveTag,
						}, {
						referenceSlug: promotionworld.leaflet.slug,
						onlyNearbyLeaflets: true,
						limit: 40,
						orderBy: 'rank',
						orderDir: 'desc',
						promotionworld: promotionworld
					}
					)
				)}
			</React.Fragment>
		);
	}


	/**
	 * A callback after selecting tag to apply its' value to promotions list filter
	 */
	private changeActiveTag = (item: IFilterChip | null): void => {
		// To switch of if the tag was already selected
		if (!item || this.state.activeTag?.id === item.id) {
			this.setState({
				activeTag: null
			});
			return;
		}

		this.setState({
			activeTag: item
		});
	}

	/**
	 * Track view
	 */
	private trackpromotionworldView = () => {
		const { promotionworld } = this.props;

		if (!promotionworld) {
			return;
		}

		if (tracked[promotionworld.leaflet.id]) {
			return;
		}

		const { leaflet } = promotionworld;

		InternalTrackingService.track('LEAFLET_OPENING', {
			leafletId: leaflet.id,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			industry_slug: leaflet.vendorIndustrySlugs?.join(','),
		});

		DataLayerService.push('LEAFLET_OPENING', {
			leafletId: leaflet.id,
			leafletTitle: leaflet.title,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
			industrySlug: leaflet.vendorIndustrySlugs?.join(','),
		});
	};


	/**
	 * Track promotion click out
	 */
	private trackPromotionClick = (promotion: IProductPromotionType, type: 'internal' | 'clickout') => {
		const { promotionworld } = this.props;
		
		// Only for promotionworld with leaflet (=we are in a Promotionworld)
		if (!promotionworld?.leaflet) {
			return;
		}

		const leaflet = promotionworld.leaflet;

		// if we got an internal click event
		if (type == 'internal') {
			InternalTrackingService.track('LEAFLET_TO_PROMOTION_CLICK', {
				leafletId: leaflet.id,
				promotionId: promotion.id,
				targetUrl: promotion.clickoutUrl,
				industry_slug: leaflet.vendorIndustrySlugs?.join(','),
				infopost: false,
			});

			DataLayerService.push('LEAFLET_TO_PROMOTION_CLICK', {
				leafletId: leaflet.id,
				leafletTitle: leaflet.title,
				vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
				vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
				pageNumber: '',
				targetUrl: promotion.clickoutUrl,
				promotionId: promotion.id,
				promotionTitle: promotion.fullDisplayName,
				industrySlug: leaflet.vendorIndustrySlugs?.join(','),
				brandId: (promotion.product && promotion.product.brand) ? promotion.product.brand.id : '',
				brandTitle: (promotion.product && promotion.product.brand) ? promotion.product.brand.name : '',
			});

			return;
		}

		// otherwise just track a clickout
		trackPromotionClickout(
			promotion,
			promotion.clickoutUrl,
			leaflet,
			null,
			null,
			null,
		);
	};


	/**
	 * Track clickout on header image 
	 */
	private trackHeaderImageClickout = () => {
		const { promotionworld } = this.props;

		// Only for promotionworld with leaflet (=we are in a Promotionworld)
		if (!promotionworld?.leaflet) {
			return;
		}

		const leaflet = promotionworld.leaflet;

		InternalTrackingService.track('CLICKOUT_LEAFLET', {
			leafletId: leaflet.id,
			targetUrl: promotionworld.promotionWorldClickoutUrl,
			industry_slug: leaflet.vendorIndustrySlugs?.join(','),
		});

		DataLayerService.push('CLICKOUT_LEAFLET', {
			leafletId: leaflet.id,
			leafletTitle: leaflet.title,
			vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
			vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
			pageNumber: '',
			industrySlug: leaflet.vendorIndustrySlugs?.join(','),
			targetUrl: promotionworld.promotionWorldClickoutUrl,
		});
	};
}

export default PromotionworldDetail;
