import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import ProductPromotionGridContainer
	from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import { IPromotionsAggregatedByStateType } from '../../../Promotion/types/IPromotionsAggregatedByStateType';
import { IBrandType } from '../../types/IBrandType';
import * as styles from './BrandDetailPage.scss';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import AdItem from '../../../../components/General/AdItem/AdItem';
import BrandListWidgetContainer from '../../../Brand/containers/BrandListWidget/BrandListWidgetContainer';
import VendorListWidgetContainer from '../../../Vendor/containers/VendorListWidget/VendorListWidgetContainer';
import { IVendorType } from '../../../Vendor/types/IVendorType';
import LeafletWidgetContainer from '../../../Leaflet/containers/LeafletWidget/LeafletWidgetContainer';

interface IBrandDetailProps {
	brand: IBrandType;
}

class BrandDetailPage extends React.Component<IBrandDetailProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};


	// TRANSLATE
	public render() {
		const { brand, routeMetadata } = this.props;

		if (!routeMetadata || !brand) {
			return null;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>

				<Row>
					<AppMainCol boxed={true} onTop={true}>

						<AppMainColBox nested={true}>

							<H1 className={styles.heading}>
								{routeMetadata.pageTitle}
							</H1>

							<div className={styles.titleWrapper}>
								<figure>
									{(!brand.resolutions) ? null :
										<div className={styles.brandLogoWrapper}>
											<ImageEnhanced
												source={brand.resolutions}
												className={styles.brandLogo}
											/>
										</div>
									}
								</figure>
								<h2 className={styles.description}>
									<span dangerouslySetInnerHTML={{ __html: brand.description }} />
									{/* Wir haben aktuell zahlreiche gültige Aktionen der Marke <b>{brand.name}</b> im Angebot! */}
								</h2>

								<img 
									className={styles.additionalImage}
									src={require('./Aeffchen-Illu-Kopf-Blick-nach-oben@3x.png')}
									alt="Aktionsfinder"
								/>
							</div>

						</AppMainColBox>

						<AppMainColBox boxed={true} nested={true}>
							<ProductPromotionGridContainer
								limit={40}
								enablePagination={true}
								brandSlug={brand.slug}
								orderBy="createdAt"
								orderDir="desc"
								gridKey={routeMetadata.queryKey}
								pushRouteMetadata={true}
								onlyNearbyPromotions={false}
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={false}>
						<div>

							<BrandListWidgetContainer
								title='Ähnliche Marken in Aktion'
								widgetKey={`brand-detail-similar-brands-widget-${brand.slug}`}
								limit={5}
								brandSlug={brand.slug}
								similar={true}
								routeCallbk={this.routerCallbkBrandListWidget}
							/>

							<LeafletWidgetContainer
								title={`Prospekte mit ${brand.name}`}
								limit={5}
								brandSlug={brand.slug}
								includeEnded={true}
								widgetKey={`brand-detail-leaflet-widget-${brand.slug}`}
							/>

							<VendorListWidgetContainer
								widgetKey={`${routeMetadata.queryKey}-vendor-widget`}
								title={`Händler mit ${brand.name} im Sortiment`}
								limit={5}
								brandSlug={brand.slug}
								routeCallbk={this.routerCallbkVendorListWidget}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							{/* <StoryListWidgetContainer
								title={`Stories`}
								widgetKey={`brand-detail-stories-${brand.slug}`}
								moreLabel="Alle Stories"
								limit={3}
								brandSlug={brand.slug}
							/> */}

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {

		return [
			{
				label: 'Marken Übersicht',
				route: 'BrandListingRoute',
			} as IBreadcrumbItemType,
			{
				label: this.props.routeMetadata ? this.props.routeMetadata.pageTitle : '',
			} as IBreadcrumbItemType,
		];
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkVendorListWidget = (item: IVendorType) => {
		const { brand } = this.props;

		return {
			name: 'PromotionsFromBrandAndVendorRoute',
			params: {
				vendor: item.slug,
				brand: brand.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IBrandType) => {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.slug,
			},
		};
	}
}

export default BrandDetailPage;
