import * as React from 'react';
import { ReactNode } from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import * as styles from './ThemeworldCardItem.scss';
import { IThemeworldType } from '../../types/IThemeworldType';

interface IThemeworldCardItemProps {
    themeworld: IThemeworldType;
	children?: ReactNode;
	subtitle?: string;
    columns?: '2' | '3';
    firstCardFullWidth?: boolean;
    themeworldIndex?: number;
}

class ThemeworldCardItem extends React.Component<IThemeworldCardItemProps> {
	public static defaultProps = {
		
	};

	public render () {
		const { themeworld, columns, firstCardFullWidth, themeworldIndex } = this.props;

        let className = `${styles.themeworldCardItem} ${styles['themeworldCardItemColumns-'+ columns]}`;
        
        if ( firstCardFullWidth && themeworldIndex === 0 ) {
            className += ` ${styles.themeworldCardFirstItem }`
        }

		// TODO: enable webp again, when resolution is fixed
		
		return (
			<Link
				route='ThemeworldDetailRoute'
				params={{themeworld: themeworld.slug}}
			>
				<a className={className}>              
                    
                    <span className={styles.themeworldCardItemImageContainer}>
					
						{ (!themeworld.resolutions && !themeworld.mobileImage.resolutions) ?
							<ProductImagePlaceholder alt={themeworld.title} />
							:
							<ImageEnhanced
								source={themeworld.mobileImage?.resolutions ?? themeworld.resolutions}
								size={firstCardFullWidth && themeworldIndex === 0 ? 'l' : 'm'}
								className={styles.themeworldCardItemImage}
							>
								<ProductImagePlaceholder alt={themeworld.title} />
							</ImageEnhanced>
						}
                    </span>

					<span className={styles.themeworldCardItemTextContainer}>

						{( !themeworld.title ) ? null :
                            <span className={styles.themeworldCardItemTitle}>{themeworld.title}</span>
                        }

                        {( !themeworld.description ) ? null :
                            <span className={styles.themeworldCardItemContent}>
                                <span dangerouslySetInnerHTML={{ __html: themeworld.description }} />
                            </span>
                        }

                    </span>
		
				</a>
			</Link>
		);
	}
}

export default ThemeworldCardItem;
