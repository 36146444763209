import { RouterProps, withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import { IRouteMetadataType } from '../App/modules/Base/types/IRouteMetadataType';
import StoryDetailContainer from '../App/modules/Story/containers/StoryDetail/StoryDetailContainer';
import DataStateService from '../App/services/DataStateService';
import { withClickbait, withTitlePostfix } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class StoryDetailRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await StoryDetailRouteProps(state);

		return { reduxData };
	}

	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4493482]}
					superbannerId={4493483}
					sitebarId={4493481}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<StoryDetailContainer
							queryKey={`storyDetail${query.story}-${DataStateService.getStateKey()}`}
							storySlug={`${query.story}`}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		StoryDetailRoute,
		(props: any) => `story-${props.match.params.story}`,
		{
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`${routeMetadata.story.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Nicht verpassen: ${routeMetadata.story.title} » mit dem Aktionsfinder-Blog informiert bleiben.`, `Jetzt entdecken!`),
		} as IRouteMetadataSetterType
	)
);
