import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import * as styles from './StoryDetail.scss';
import SkeletonInline from '../../../../../App/components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../../App/components/Layout/Skeleton/SkeletonBlock';
import ProductPromotionGridSkeleton from '../../../../../App/modules/Promotion/components/ProductPromotionGrid/ProductPromotionGridSkeleton';
import NearsetShopWidgetSkeleton from '../../../../modules/Vendor/components/NearestShopWidget/NearestShopWidgetSkeleton'
import StoryCardWidgetSkeleton from '../StoryCardWidget/StoryCardWidgetSkeleton';
import WidgetBoxSkeleton from '../../../../../App/components/Layout/WidgetBox/WidgetBoxSkeleton';


class StoryDetailSkeleton extends React.PureComponent<{}> {
    public static defaultProps = {};


    public render() {
        const fakeArr = Array(10).fill(' ');
        const skeletonArticle = fakeArr.map((line, index) => {
            return <div className={styles.skeletonArticleList} key={index}><SkeletonBlock style={{ height: 40 }} /></div>
        });

        return (
            <PageWrapper>
                <SkeletonInline />
                <Row>
                    <AppMainCol boxed={true}>
                        <AppMainColBox boxed={true} nested={true}>

                            <React.Fragment>
                                <div className={styles.storyDetailPostedDate}>

                                    <H1>
                                        <SkeletonInline />
                                    </H1>

                                    <div className={styles.storyDetailPostedDateText}>
                                        <div className={styles.subHeaderSkeleton}>
                                            <a>
                                                <SkeletonBlock style={{ height: 150 }} />
                                            </a>
                                        </div>

                                    </div>
                                </div>

                                <div className={styles.storyContent}>
                                    {skeletonArticle}
                                </div>


                                <div>
                                    <p>&nbsp;</p>
                                    <NearsetShopWidgetSkeleton />
                                </div>


                                <ProductPromotionGridSkeleton />
                            </React.Fragment>

                        </AppMainColBox>

                        <p>&nbsp;</p>

                        <AppMainColBox boxed={true}>
                            <NewsletterSignupComponent />
                        </AppMainColBox>

                        <AppMainColBox nested={true} boxed={true}>

                            <StoryCardWidgetSkeleton columns={2} />
                            <p>&nbsp;</p>
                        </AppMainColBox>

                    </AppMainCol>
                    <AppSideCol boxed={false}>

                        <WidgetBoxSkeleton
                            rows={6}
                            type="item"
                            showValues={{
                                title: true,
                                subtitle: false,
                                special: false,
                                extra: false,
                            }}
                        />

                    </AppSideCol>
                </Row>
            </PageWrapper >
        );
    }
}


export default StoryDetailSkeleton;
