import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import ProductPromotionGridContainer
	from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import ProductPromotionListWidgetContainer
	from '../../../Promotion/containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import PromotionsAggregatedByBrandsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import { IPromotionsAggregatedByStateType } from '../../../Promotion/types/IPromotionsAggregatedByStateType';
import { IProductGroupType } from '../../types/IProductGroupType';
import { SlugType } from '../../../Base/types/SlugType';
import StoryListWidgetContainer from '../../../Story/containers/StoryListWidget/StoryListWidgetContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IProductGroupDetailDestinationPageProps {
	productGroup: IProductGroupType;
	productGroupSlug: SlugType;
}

class ProductGroupDetailDestinationPage extends React.Component<IProductGroupDetailDestinationPageProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	// TRANSLATE
	public render() {
		const { productGroup, routeMetadata, productGroupSlug } = this.props;

		const productGroupTitle = (productGroup) ? productGroup.title : '';

		return (
			<React.Fragment>

				<AdItem
					desktopAdId={4403492}
					maxHeight={250}
					type="billboard"
				/>
				
				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>

					<AppMainCol boxed={false}>
						<AppMainColBox boxed={true} nested={true}>

							<ProductPromotionGridContainer
								gridKey={routeMetadata.queryKey}
								limit={20}
								enablePagination={true}
								productGroupSlug={productGroupSlug}
								pushRouteMetadata={true}
								orderBy="createdAt"
								orderDir="desc"
								contentBreaks={{
									2: (
										<AdItem
											mobileAdId={4403480}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									6: (
										<AdItem
											mobileAdId={4403479}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError />
							</ProductPromotionGridContainer>
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} h1Outside={false} onTop={true}>
						<div>
							<PromotionsAggregatedByProductGroupsWidgetContainer
								title="Ähnliche Produktkategorien"
								widgetKey={`subcategory-widget-reference-${productGroupSlug}`}
								referenceSlug={productGroupSlug}
								exclude={productGroupSlug}
								routeCallbk={this.routerCallbkProductGroupListWidget}
								onlyNearby={true}
							/>

							<PromotionsAggregatedByStatesWidgetContainer
								title={`${productGroupTitle} Aktionen nach Bundesland`}
								widgetKey={`productgroup-detail-aggregated-states-widget-${productGroupSlug}`}
								routeCallbk={this.routerCallbkStateListWidget}
								productGroupSlug={productGroupSlug}
							/>

							<AdItem
								desktopAdId={4403491}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<PromotionsAggregatedByBrandsWidgetContainer
								title={`${productGroupTitle} Marken in Aktion`}
								widgetKey={`productgroup-detail-aggregated-brands-widget-${productGroupSlug}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								productGroupSlug={productGroupSlug}
							/>

							<ProductPromotionListWidgetContainer
								widgetKey={`productgroup-detail-promotion-list-coupons-${productGroupSlug}`}
								title={`${productGroupTitle} Gutscheine`}
								fetchVoucherProductPromotions={true}
								productGroupSlug={productGroupSlug}
								noGeolocation={true}
								placeholderLimit={6}
								actionButtonLabel={`Alle ${productGroupTitle} Gutscheine`}
								actionButtonRouteCallbk={this.routerCallbkVoucherListWidget}
							/>

							<ProductPromotionListWidgetContainer
								widgetKey={`productgroup-detail-promotion-list-assortments-${productGroupSlug}`}
								title={`${productGroupTitle} Sortimentsaktionen`}
								fetchAssortementProductPromotions={true}
								productGroupSlug={productGroupSlug}
								noGeolocation={true}
								placeholderLimit={6}
								actionButtonLabel={`Alle ${productGroupTitle} Sortimentsaktionen`}
								actionButtonRouteCallbk={this.routerCallbkPromotionListWidget}
							/>

							<StoryListWidgetContainer
								title={`Stories`}
								widgetKey={`productgroup-dest-stories-${productGroupSlug}`}
								moreLabel="Alle Stories"
								limit={3}
								directProductGroupSlug={productGroupSlug}
							/>
							
						</div>
					</AppSideCol>
				</Row>
			</React.Fragment>
		);
	}

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkVoucherListWidget = () => {
		const { productGroupSlug } = this.props;

		return {
			name: 'VouchersInProductGroupRoute',
			params: {
				productGroup: productGroupSlug,
			},
		};
	};

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = () => {
		const { productGroupSlug } = this.props;

		return {
			name: 'AssortementPromotionsInProductGroupRoute',
			params: {
				productGroup: productGroupSlug,
			},
		};
	};

	/**
	 * Defines the route the product group list widget should be routed towards
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		return {
			href: item.group.detailUrlPath,
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStateListWidget = (item: IPromotionsAggregatedByStateType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndStateRoute',
			params: {
				state: item.group.slug,
				productGroup: productGroupSlug,
			},
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { productGroupSlug } = this.props;

		return {
			name: 'PromotionsFromProductGroupAndBrandRoute',
			params: {
				productGroup: productGroupSlug,
				brand: item.group.slug,
			},
		};
	}
}

export default ProductGroupDetailDestinationPage;
