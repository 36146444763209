import { IStoryType } from 'App/modules/Story/types/IStoryType';
import * as React from 'react';
import { withRouter } from 'react-router';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import MobileAppWidget from '../App/components/Layout/MobileAppWidget/MobileAppWidget';
import AppSideCol from '../App/components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../App/components/Layout/PageWrapper/PageWrapper';
import Row from '../App/modules/Base/components/Grid/Row';
import BrandListWidgetContainer from '../App/modules/Brand/containers/BrandListWidget/BrandListWidgetContainer';
import { IBrandWidgetType } from '../App/modules/Brand/types/IBrandWidgetType';
import CurrentLeafletWidgetContainer
	from '../App/modules/Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import LeafletsAggregatedByStatesWidgetContainer
	from '../App/modules/Leaflet/containers/LeafletsAggregatedByStatesWidget/LeafletsAggregatedByStatesWidgetContainer';
import { ILeafletsAggregatedByStateType } from '../App/modules/Leaflet/types/ILeafletsAggregatedByStateType';
import PromotionsAggregatedByBrandsWidgetContainer
	from '../App/modules/Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer
	from '../App/modules/Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import PromotionsAggregatedByStatesWidgetContainer
	from '../App/modules/Promotion/containers/PromotionsAggregatedByStatesWidget/PromotionsAggregatedByStatesWidgetContainer';
import PromotionsAggregatedByVendorsWidgetContainer
	from '../App/modules/Promotion/containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../App/modules/Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../App/modules/Promotion/types/IPromotionsAggregatedByProductGroupType';
import { IPromotionsAggregatedByStateType } from '../App/modules/Promotion/types/IPromotionsAggregatedByStateType';
import { IPromotionsAggregatedByVendorType } from '../App/modules/Promotion/types/IPromotionsAggregatedByVendorType';
import StoryCategoryListWidgetContainer from '../App/modules/Story/containers/StoryCategoryListWidget/StoryCategoryListWidgetContainer';
import StoryListWidgetContainer from '../App/modules/Story/containers/StoryListWidget/StoryListWidgetContainer';
import TagCloudWidgetContainer from '../App/modules/Tag/containers/TagCloudWidget/TagCloudWidgetContainer';
import ThemeworldListWidgetContainer
	from '../App/modules/Themeworld/containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from '../App/modules/Themeworld/types/IThemeworldWidget';
import ShopLocationMapWidget from '../App/modules/Vendor/components/ShopLocationMapWidget/ShopLocationMapWidget';
import ShopListWidgetContainer from '../App/modules/Vendor/containers/ShopListWidgetContainer/ShopListWidgetContainer';
import VendorLogoWidgetContainer
	from '../App/modules/Vendor/containers/VendorLogoWidgetContainer/VendorLogoWidgetContainer';
import StoryCardWidgetContainer
	from '../App/modules/Story/containers/StoryCardWidgetContainer/StoryCardWidgetContainer';


class Widgets extends React.Component<{}, {}> {

	public render() {

		const brandSlug = "markemit%20bild;";
		const vendorSlug = "billa";

		return (
			<div>
				<Header />
				<MainNavigation />

				<PageWrapper
					title="Widgets"
					titleAlign="left"
				>

					<Row>

						<AppSideCol boxed={true}>
							<div>
								<section>
									<BrandListWidgetContainer
										widgetKey="promotions-brand-widget"
										title="Beliebte Marken"
										limit={5}
										routeCallbk={this.routerCallbkBrandListWidget}
									/>
								</section>
								<section>
									<LeafletsAggregatedByStatesWidgetContainer
										title={"Prospekte nach Bundesland"}
										limit={10}
										widgetKey={`widgets-aggregated-leaflets-vendors-widget-test`}
										routeCallbk={this.routerCallbkPromotionsLeafletsAggregatedByStatesWidget}
									/>
								</section>
								<section>
									<PromotionsAggregatedByProductGroupsWidgetContainer
										title={"Aktionen nach Kategorie"}
										limit={10}
										widgetKey={`widgets-aggregated-promotion-product-group-widget-test`}
										routeCallbk={this.routerCallbkPromotionsAggregatedByProductGroupsWidget}
									/>
								</section>
								<section>
									<ShopListWidgetContainer
										widgetKey="promotions-shops-popularity"
										title="Beliebte Märkte"
										orderBy="rank"
										limit={5}
									/>
								</section>
								<section>
									<CurrentLeafletWidgetContainer
										title={`Aktuelle Prospekte`}
										widgetKey={`current-details-leaflets-${vendorSlug}`}
										limit={3}
										vendorSlug={vendorSlug}
										btnLabel={`Alle Billa Prospekte`}
										btnRoute="LeafletsByVendorPage"
										btnParams={{
											vendor: vendorSlug,
										}}
									/>
								</section>

							</div>
						</AppSideCol>


						<AppSideCol boxed={true}>
							<div>
								<section>
									<TagCloudWidgetContainer
										title="Schlagworte"
										widgetKey="tag-cloud"
										limit={5}
										routeCallbk={this.routerCallbkTagListWidget}
									/>
								</section>
								<section>
									<ThemeworldListWidgetContainer
										widgetKey="promotions-themeworld-widget"
										title="Themenwelten"
										routeCallbk={this.routerCallbkThemeworldListWidget}
										limit={5}
										routeCallbk={this.routerCallbkThemeworldWidgetContainer}
									/>
								</section>
								<section>
									<PromotionsAggregatedByBrandsWidgetContainer
										title={"Aktionen nach Marke"}
										limit={10}
										widgetKey={`widgets-aggregated-promotion-brands-widget-test`}
										routeCallbk={this.routerCallbkPromotionsAggregatedByBrandsWidget}
									/>
								</section>
								<section>
									<PromotionsAggregatedByVendorsWidgetContainer
										title={"Aktionen nach Händler"}
										limit={10}
										widgetKey={`widgets-aggregated-promotion-vendors-widget-test`}
										routeCallbk={this.routerCallbkPromotionsAggregatedByVendorsWidget}
									/>
								</section>
								<section>
									<PromotionsAggregatedByStatesWidgetContainer
										title={"Aktionen nach Bundesland"}
										limit={10}
										widgetKey={`widgets-aggregated-promotion-states-widget-test`}
										routeCallbk={this.routerCallbkPromotionsAggregatedByStatesWidget}
									/>
								</section>

							</div>
						</AppSideCol>


						<AppSideCol boxed={true}>
							<div>
								<section>
									<StoryListWidgetContainer
										title="Unsere stories"
										widgetKey="story-widget-small"
										moreLabel="Weitere Stories"
										limit={6}
									/>
								</section>
								<section>
									<StoryCategoryListWidgetContainer
										title="Story Kategorien"
										widgetKey="story-categories"
										routeCallbk={this.routerCallbkStoryCategoryListWidgetContainer}
										limit={6}
									/>
								</section>
								<section>
									<ShopListWidgetContainer
										widgetKey="promotions-shops-nearby"
										title="Märkte in deiner Nähe"
										orderBy="distance"
										limit={5}
									/>
								</section>
								<section>
									<ShopLocationMapWidget
										title={`Dein Weg zu XY`}
									/>
								</section>
								<section>
									<MobileAppWidget />
								</section>
							</div>
						</AppSideCol>

					</Row>

					<div style={{ height: 60 }} />

					<VendorLogoWidgetContainer
						widgetKey="widgets-vendors-logos-popular"
						title="Beliebte Händler"
						orderBy="popularity"
					/>

					<div style={{ height: 60 }} />


					<StoryCardWidgetContainer
						widgetKey="story-categories"
						size={'l'}
						limit={3}
						routeCallbk={this.routerCallbkStoryListWidgetContainer}
						moreLabel="Weitere Stories ..."
						moreRoute="StoryListingRoute"
						enablePagination={false}
					/>

					<div style={{ height: 60 }} />

					<div style={{ height: 60 }} />

					<div style={{ height: 60 }} />

				</PageWrapper>

			</div>
		);
	}

	/**
 * Defines the route the product group list widget should be routed towards
 *
 * @param item
 */
	private routerCallbkTagListWidget = (item: ITagWidgetItemType) => {

		return {
			name: 'TagDetailRoute',
			params: {
				tag: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 * @param item
	 */
	private routerCallbkPromotionsAggregatedByProductGroupsWidget(item: IPromotionsAggregatedByProductGroupType) {
		return {
			href: item.group.detailUrlPath,
		};
	}

	/**
 * Defines the route the product group list widget should be routed towards
 * @param item
 */
	private routerCallbkPromotionsLeafletsAggregatedByStatesWidget(item: ILeafletsAggregatedByStateType) {
		return {
			name: 'LeafletsInStateRoute',
			params: {
				state: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkPromotionsAggregatedByVendorsWidget = (item: IPromotionsAggregatedByVendorType) => {
		return {
			name: 'VendorDetailRoute',
			params: {
				vendor: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkPromotionsAggregatedByBrandsWidget = (item: IPromotionsAggregatedByBrandType) => {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: 'brand',
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkPromotionsAggregatedByStatesWidget = (item: IPromotionsAggregatedByStateType) => {
		return {
			name: 'PromotionsByStatesRoute',
			params: {
				state: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the brand list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IBrandWidgetType) => {
		return {
			name: 'BrandDetailRoute',
			params: {
				brand: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStoryCategoryListWidgetContainer = (item: IStoryCategoryType) => {
		return {
			name: 'StoriesInCategoryRoute',
			params: {
				storyCategory: item.slug,
			},
		};
	}

	/**
	 * Defines the route the themeworld list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkThemeworldWidgetContainer = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}
}

export default Widgets;
