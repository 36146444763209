import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import StoryCardWidget from '../../components/StoryCardWidget/StoryCardWidget';
import StoryCardWidgetSkeleton from '../../components/StoryCardWidget/StoryCardWidgetSkeleton';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import { IStoryType } from '../../types/IStoryType';

interface IStoryCardWidgetContainerProps {
	title?: string;
	widgetKey: string;
	moreRoute?: string;
	moreLabel?: string;
	limit?: number;
	placeholderLimit?: number;
	page?: number;
	columns?: '2' | '3';
	firstCardFullWidth?: boolean; // Property, which makes first card on full width of container (used on /stories page)
	boxed?: boolean;
	style?: IWidgetStyleType;
	excludeSlug?: string;
	titleLevel?: 'h1' | 'h2' | 'h3' | 'h4';
	enablePagination?: boolean;
	
	vendorSlug?: string;
	promotionSlug?: string;
}

class StoryCardWidgetContainerInner extends React.Component<IStoryCardWidgetContainerProps & IWithDataProps> {

	public render() {
		const {
			data,
			excludeSlug,
			...restProps
		} = this.props;

		const storyData = excludeSlug ? this.excludedSlugStory(data) : data;

		if (data.length < 1) {
			return null;
		}

		return (
			<StoryCardWidget
				stories={storyData as IStoryType[]}
				{...restProps}
			/>
		);
	}

	/**
	 * Cleans up our entries and makes sure the excluded entry is not in there
	 *
	 * @param {IProductPromotionType} entries
	 *
	 * @return {IProductPromotionType}
	 */

	private excludedSlugStory(
		entries: IProductPromotionType[],
	): IProductPromotionType[] {
		const { limit, excludeSlug } = this.props;

		// now try to filter it out
		return entries.filter((entry: IProductPromotionType, indx: number) => {
			if (entry.slug === excludeSlug) {
				return false;
			}

			// if we are over our limit -> also return false (this might be because, we don't have the excludee in our data set,
			// but to make sure we have the correct amount of data even after removing the excludee, we have added +1 to limit on fetch
			if (limit && indx > limit) {
				return false;
			}

			// otherwise return true and add it to our results
			return true;
		});
	}
}

export default function StoryCardWidgetContainer(
	props: IStoryCardWidgetContainerProps,
) {
	let limit = props.limit ? props.limit : 5;

	if (props.excludeSlug) {
		limit++;
	}

	const RenderComponent = withData(
		StoryCardWidgetContainerInner,
		'storyListWidgetReducer',
		`story-card-widget-${props.widgetKey}`,
		{
			page: props.page,
			size: limit,
			store_vendor: props.vendorSlug,

			promotion: props.promotionSlug,
		},
		{
			LoadingComponent: StoryCardWidgetSkeleton,
		},
	);

	return <RenderComponent {...props} />;
}
