import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as storyListWidgetActions from './storyListWidgetActions';

export type StoryListWidgetAction = ActionType<typeof storyListWidgetActions>;

export interface IStoryListWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IStoryType };
	readonly queries: { [queryKey: string]: IStoryType[] };
}

export function storyListWidgetReducer(
	state = baseInitialState as IStoryListWidgetStateType,
	action: StoryListWidgetAction,
) {
	const Reducer = new BaseReducer('storyListWidgetReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}