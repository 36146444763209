import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import FlexItem from '../../../../components/Layout/Flexbox/FlexItem';
import H1 from '../../../../components/Layout/H1/H1';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import BookmarkIconContainer from '../../../Bookmark/containers/BookmarkIcon/BookmarkIconContainer';
import CurrentLeafletWidgetContainer from '../../../Leaflet/containers/CurrentLeafletWidget/CurrentLeafletWidgetContainer';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import ProductPromotionGridGroupedContainer from '../../../Promotion/containers/ProductPromotionGridGrouped/ProductPromotionGridGroupedContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IProductPromotionGroupedType } from '../../../Promotion/types/IProductPromotionGroupedType';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import ShopListWidgetContainer from '../../containers/ShopListWidgetContainer/ShopListWidgetContainer';
import { IShopType } from '../../types/IShopType';
import { IVendorType } from '../../types/IVendorType';
import Distance from '../Distance/Distance';
import ShopLocationMapWidget from '../ShopLocationMapWidget/ShopLocationMapWidget';
import * as styles from './ShopDetail.scss';
import ShopDetailInfo from './ShopDetailInfo';
import ShopDetailInfoSkeleton from './ShopDetailInfoSkeleton';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';
import { SlugType } from '../../../Base/types/SlugType';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import StoryListWidgetContainer from '../../../Story/containers/StoryListWidget/StoryListWidgetContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IShopProps {
	shop: IShopType | null;
	shopSlug: SlugType;
}

class ShopDetail extends React.Component<IShopProps, {}> {
	public static defaultProps = {};

	// TRANSLATE
	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		const { shop } = this.props;

		if (!shop) {
			return [
				{
					label: 'Händler Übersicht',
					route: 'VendorListingRoute'
				} as IBreadcrumbItemType,
				{
					label: 'Händler',
					route: 'HomeRoute'
				} as IBreadcrumbItemType,
				{
					label: 'Markt Detailseite',
					route: 'HomeRoute'
				} as IBreadcrumbItemType
			];
		}

		const shopTitle = shop.title ? shop.title : '';
		const vendorName = shop.vendor ? shop.vendor.name : '';

		return [
			{
				label: 'Händler Übersicht',
				route: 'VendorListingRoute'
			} as IBreadcrumbItemType,
			{
				label: vendorName,
				route: 'VendorDetailRoute',
				routeParams: { vendor: shop.vendor.slug }
			} as IBreadcrumbItemType,
			{
				label: shopTitle
			} as IBreadcrumbItemType
		];
	}

	public render() {
		const { shop, shopSlug } = this.props;

		const vendor = shop && shop.vendor ? shop.vendor : {} as IVendorType;
		const vendorTitle = shop && shop.vendor && shop.vendor.name ? shop.vendor.name : '';

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<div>&nbsp;</div>
				<Row>
					<AppMainCol boxed={false}>
						<AppMainColBox boxed={true}>

							{shop ? (
								<React.Fragment>
									<div className={styles.shopHeader}>
										<FlexItem grow={1}>
											<H1>
												{shop.title}, {shop.address}, {shop.zipCode} {shop.city}
											</H1>
											<div className={styles.shopLocation}>
												{!vendor || !vendor.slug ? null : (
													<span className={styles.bookmarkVendor}>
														<BookmarkIconContainer entity="vendor" slug={vendor.slug} label="Händler als Favorit" />
													</span>
												)}

												<Distance distanceInMeters={shop.distance} />
											</div>
										</FlexItem>

										<div className={styles.shopDetailLogoContainer}>
											<div className={styles.shopDetailLogo}>
												<figure>
													{!vendor.resolutions ? null : (
														<ImageEnhanced source={vendor.resolutions} alt={`${vendorTitle} Logo`} />
													)}
												</figure>
											</div>
										</div>
									</div>

									<ShopDetailInfo shop={shop} /> 
								</React.Fragment>
							) : (
								<React.Fragment>
									<div className={styles.shopHeader}>
										<section>
											<FlexItem grow={1}>
												<H1>
													<SkeletonInline width={300} />
													<SkeletonInline width={300} />
												</H1>
												<div className={styles.shopLocation}>
													<SkeletonInline />
												</div>
											</FlexItem>
										</section>

										<FlexItem shrink={1}>
											<div className={styles.shopDetailLogo}>
												<SkeletonInline width={60} />
											</div>
										</FlexItem>
									</div>
									<ShopDetailInfoSkeleton />
								</React.Fragment>
							)}
							

							<div className={styles.tagCloudWidgetContainer}>
								<PromotionsAggregatedByProductGroupsWidgetContainer
									title={`Finde in diesem Markt was du suchst`}
									widgetKey={`shop-product-group-cloud-${shopSlug}`}
									style="tagcloud"
									shopSlug={shopSlug}
									limit={10}
									routeCallbk={this.routerCallbkProductGroupListWidget}
									moreLabel="Weitere Aktionen"
									moreRoute="PromotionListingRoute"
								/>
							</div>

							<ProductPromotionGridGroupedContainer
								columns={'2'}
								gridKey={`shop-product-group-${shopSlug}`}
								moreLabel={`Weitere %title% Aktionen in diesem ${vendorTitle} Markt`}
								shopSlug={shopSlug}
								onlyNearbyPromotions={false}
								moreRouteCallbk={this.routerCallbkPromotionListWidget}
								useLeafletRoute={false}
								contentBreaks={{
									1: (
										<AdItem
											mobileAdId={4403482}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 1"
										/>
									),
									3: (
										<AdItem
											mobileAdId={4403481}
											maxHeight={250}
											type="content"
											title="Mobile Rectangle 2"
										/>
									)
								}}
							>
								<NoDataError
									nested={true}
									title="Wir konnten leider keine aktuellen Aktionen für diesen Markt finden."
								/>
							</ProductPromotionGridGroupedContainer>

							<p>&nbsp;</p>

							<NewsletterSignupComponent />
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={false}>
						<div>
							{/*shop && shop.latitude && shop.longitude ? (
								<ShopLocationMapWidget
									title={`Dein Weg zu diesem Markt`}
									center={{
										lat: shop.latitude,
										lng: shop.longitude
									}}
								/>
							) : (
								<SkeletonBlock />
							)*/}

							{/*<PromotionsAggregatedByBrandsWidgetContainer
								widgetKey={`shopdetail-brand-widget-${
									shopSlug
									}`}
								title={`Beliebte Marken in diesem Markt`}
								routeCallbk={this.routerCallbkBrandListWidget}
								shopSlug={shopSlug}
								limit={10}
							/>*/}

							{shop ? (
								<React.Fragment>
									<CurrentLeafletWidgetContainer
										widgetKey={`promotions-leaflet-widget-${shopSlug}`}
										title={`Die aktuellen ${vendorTitle} Prospekte`}
										btnLabel={`Alle ${vendorTitle} Prospekte`}
										vendorSlug={vendor.slug}
										limit={3}
										orderBy="validFrom"
										orderDir="asc"
										btnRoute="LeafletsByVendorRoute"
										btnParams={{
											vendor: shop.vendor.slug
										}}
									/>

									<AdItem
										desktopAdId={4403494}
										maxHeight={250}
										maxWidth={300}
										type="content"
										title="Medium Rectangle 1"
									/>

									<ShopListWidgetContainer
										widgetKey={`shopdetail-shops-nearby-${shopSlug}`}
										title={`Weitere ${vendorTitle} Märkte in deiner Nähe`}
										exclude={shopSlug}
										vendorSlug={shop.vendor.slug}
										orderBy="distance"
										orderDir="desc"
										limit={5}
									/>

									<StoryListWidgetContainer
										title={`Stories`}
										widgetKey={`shop-detail-stories-${shopSlug}`}
										moreLabel="Alle Stories"
										limit={3}
										shopSlug={shopSlug}
									/>
									
								</React.Fragment>
							) : null}
						</div>
					</AppSideCol>
				</Row>
			</PageWrapper>
		);
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { shopSlug } = this.props;

		return {
			name: 'PromotionsInShopAndProductGroupRoute',
			params: {
				productGroup: item.group.slug,
				shop: shopSlug
			}
		};
	};

	/**
	 * Defines the route the promotion list widget should be routed towards
	 */
	private routerCallbkPromotionListWidget = (item: IProductPromotionGroupedType) => {
		const { shopSlug } = this.props;

		return {
			name: 'PromotionsInShopAndProductGroupRoute',
			params: {
				shop: shopSlug,
				productGroup: item.group.slug
			}
		};
	};

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { shopSlug } = this.props;

		return {
			name: 'PromotionsInShopByBrandRoute',
			params: {
				brand: item.group.slug,
				shop: shopSlug
			}
		};
	};
}

export default ShopDetail;
