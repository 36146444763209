import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';

interface IStoryCategoryListWidgetProps {
	stories: IStoryCategoryType[];
	title: string;
	widgetKey?: string;
	promotionLabel?: string;
	promotionLabelPlural?: string;

	routeCallbk: (item: IStoryCategoryType) => { name: string, params: {[key: string]: any} },
}

class StoryCategoryListWidget extends React.Component<IStoryCategoryListWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'stories-list-widget'
	};

	public render() {
		const { stories, title, widgetKey, routeCallbk } = this.props;

		return (
			<WidgetBox title={title}>
				<WidgetBoxContent>
					{stories.map((storyCategory: IStoryCategoryType) => {
						const route = routeCallbk(storyCategory);

						return (
							<WidgetListItemLinkRow
								title={storyCategory.title}
								key={`${widgetKey}-${storyCategory.slug}`}
								route={route.name}
								routeParams={route.params}
							/>
						);
					})}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default StoryCategoryListWidget;
