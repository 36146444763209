/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
// import { IVendorWidgetType } from '../../types/IVendorWidgetType';

export const STORY_CATEGORY_LIST_WIDGET_FETCH_ENTRIES = '@BasePipeline/storyCategoryListWidgetReducer_FETCH_ENTRIES';
export const STORY_CATEGORY_LIST_WIDGET_FETCH_ENTRY   = '@BasePipeline/storyCategoryListWidgetReducer_FETCH_ENTRY';
export const STORY_CATEGORY_LIST_WIDGET_ADD_ENTRIES = '@BasePipeline/storyCategoryListWidgetReducer_ADD_ENTRIES';
export const STORY_CATEGORY_LIST_WIDGET_ADD_ENTRY = '@BasePipeline/storyCategoryListWidgetReducer_ADD_ENTRY';

export const STORY_CATEGORY_LIST_WIDGET_SET_ERRORS   = '@BasePipeline/storyCategoryListWidgetReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchStoryCategoryListWidgets (queryKey: string) {
	return {
		type: STORY_CATEGORY_LIST_WIDGET_FETCH_ENTRIES,
		queryKey,
	};
}

export function addStoryCategoryListWidgetEntries (queryKey: string, entries: IStoryCategoryType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: STORY_CATEGORY_LIST_WIDGET_ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addStoryCategoryListWidgetEntry (queryKey: string, entry: IStoryCategoryType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: STORY_CATEGORY_LIST_WIDGET_ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setStoryCategoryListWidgetErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: STORY_CATEGORY_LIST_WIDGET_SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
