import * as React from 'react';
import Icon from '../../../modules/Base/components/Icon/Icon';
import Link from '../../../modules/Base/components/Link/Link';
import LayoutService from '../../../services/LayoutService';
import * as styles from './Footer.scss';

interface IFooterProps {
    fluid?: boolean,
}

class Footer extends React.Component<IFooterProps> {
    public render() {
        
        if (LayoutService.isAppShellDisabled()) {
			return null;
		}

        const { fluid } = this.props;

        return (
            <React.Fragment>
                <footer className={`${styles.wrapper} ${( fluid ) ? styles.fluid : ''}`}>
                    <div className={styles.socialLinks}>
                        <Link route="FeedbackRoute">
                            <a>
                                <Icon
                                    icon="send"
                                />&nbsp;
                                Feedback senden
                            </a>
                        </Link>

                        <a
                            href="https://www.facebook.com/Aktionsfinder/" target="_blank"
                            rel="nofollow noopener noreferrer"
                        >
                            <Icon
                                icon="facebook"
                            />&nbsp;
                            Facebook
                        </a>
                    </div>

                    <div className={styles.companyBar}>
                        <div className={styles.legalLinks}>
                            <Link route="ImprintRoute">
                                <a>
                                    Impressum
                                </a>
                            </Link>

                            <Link route="PrivacyInfoRoute">
                                <a>
                                    Datenschutz
                                </a>
                            </Link>

                            <Link route="TermsAndConditionsRoute">
                                <a>
                                    Nutzungsbedingungen
                                </a>
                            </Link>

                            {/*<!-- OneTrust Cookies Settings button start -->*/}
                                <button id="ot-sdk-btn" className={`ot-sdk-show-settings ${styles.legalLinkItem}`}>Cookie Einstellungen</button>
                            {/*<!-- OneTrust Cookies Settings button end -->*/}

                        </div>

                        <div className={styles.copy}>
                            © Österreichische Post AG, Unternehmenszentrale: Rochusplatz 1, 1030
                            Wien
                        </div>
                    </div>
                </footer>
            </React.Fragment>
        );
    }
}

export default Footer;
