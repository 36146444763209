import * as React from 'react';
import * as styles from './PromotionworldGridItem.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import { ReactNode } from 'react';
import Currency from '../../../Base/components/Currency/Currency';
import Percentage from '../../../Base/components/Percentage/Percentage';
import { DateTime } from 'luxon';
import { IProductPromotionType } from '../../../Promotion/types/IProductPromotionType';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import { IImageResolutions } from '../../../Base/types/IImageResolutions';
import Icon from '../../../Base/components/Icon/Icon';
import Link from '../../../Base/components/Link/Link';

interface IPromotionworldGridItemProps {
	promotion: IProductPromotionType;
	onPromotionClick: (promotion: IProductPromotionType) => void;
	directLinkClickouts: boolean;
}

class PromotionworldGridItem extends React.Component<IPromotionworldGridItemProps, {}> {
	public render() {
		const { promotion, onPromotionClick, directLinkClickouts } = this.props;

		const validFrom = (promotion.validFrom) ? DateTime.fromISO(promotion.validFrom) : null;
		const validTo = (promotion.validTo) ? DateTime.fromISO(promotion.validTo) : null;

		const discountValue = this.getDiscountValue();
		const productImageSrc = this.getResultImage(promotion);

		const promotionTitle = promotion.fullDisplayName ?? promotion.title;

		const PromotionItem = (props: { type: 'clickout' | 'internal' }) => (
			<span className={styles.PromotionworldGridItemWrapperInner} >
				<span className={styles.ImageWrapper}>
					<ImageEnhanced
						source={productImageSrc}
						bgImage={true}
					/>
				</span>

				<span className={styles.Content}>
					<span className={styles.Heading}>
						{promotionTitle}
						{/* // if title is too long -> show fade out */}
						{(promotionTitle?.length < 55) ? null :
							<span className={styles.HeadingFadeout} />
						}
					</span>

					<span className={styles.ActionBar}>
						<span className={styles.ActionBarGrow} style={promotion.vendor && promotion.vendor.resolutions ? {display: 'flex'} : {}}>
							{promotion.vendor && promotion.vendor.resolutions ? 
								<ImageEnhanced
									source={promotion.vendor.resolutions}
									className={styles.productPromotionVendorItemImage}
									alt={promotion.vendor.name}
									size={'s'}
								/> : `${promotion.vendorName}`
							}

							{(!validFrom || !validTo) ? null :
								((promotion.openValidity) ?
									<HumanReadableDate
										from={validFrom}
										to={validTo}
										outputIfEnded="abgelaufen"
										openValidity={promotion.openValidity}
									/> :
									<span>
										{validFrom.toFormat('dd.LL.')} - {validTo.toFormat('dd.LL.yy')}
									</span>
								)
							}
						</span>
					</span>

					<span className={styles.ActionBar}>
						<span className={styles.ActionBarGrow}>
							{(!promotion.originalPrice) ? <span className={`${styles.Line} ${styles.Price} `}></span> :
								<span className={`${styles.Line} ${styles.OldPrice} `}>
									<Currency amount={promotion.originalPrice} />
								</span>
							}

							{(!discountValue) ? <span className={`${styles.Line} ${styles.Price} `}></span> :
								<span className={`${styles.Line} ${styles.Price} `}>
									{discountValue}
								</span>
							}
						</span>
						<span className={styles.ActionBarShrink}>
							<Link route='AppExclusiveRoute'>
								<a className={styles.Bookmark}>
									<Icon icon='shoppinglist-add' />
								</a>
							</Link>
							{props.type === 'clickout' ?
								<Icon icon="hotspot-link" className={styles.icon} /> :
								<Icon icon="info" className={styles.icon} />
							}

						</span>
					</span>
				</span>
			</span>

		);

		if (!directLinkClickouts || !promotion.clickoutUrl) {
			const internalRoute = {
				name: 'PromotionDetailRoute',
				params: {
					promotion: promotion.slug,
				}
			};

			return (
				<Link
					route={internalRoute.name}
					params={internalRoute.params}
				>
					<a className={styles.PromotionworldGridItemWrapper} onClick={() => onPromotionClick(promotion, 'internal')}>
						<PromotionItem type="internal" />
					</a>
				</Link>
			);
		}

		return (
			<a href={promotion.clickoutUrl} target='_blank' className={styles.PromotionworldGridItemWrapper} onClick={() => onPromotionClick(promotion, 'clickout')}>
				<PromotionItem type="clickout" />
			</a>
		);
	}


	/**
	 * Returns the correct discount value based on the available discounts we have
	 *
	 * @return {string}
	 */
	private getDiscountValue(): string | ReactNode {
		const { promotion } = this.props;

		if (promotion.discountedPrice) {
			return (
				<Currency
					amount={promotion.discountedPrice}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.newPrice) {
			return (
				<Currency
					amount={promotion.newPrice}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.discountAmount) {
			return (
				<Currency
					amount={promotion.discountAmount}
					currency={promotion.currency}
				/>
			);
		}

		if (promotion.discountPercentage) {
			return <Percentage amount={promotion.discountPercentage} />;
		}

		return '';
	}

	private getResultImage(promotion: IProductPromotionType): IImageResolutions {

		if (promotion.images && promotion.images[0]) {
			return promotion.images[0].resolutions;
		}

		if (promotion.product?.productImages[0]?.resolutions) {
			return promotion.product?.productImages[0]?.resolutions;
		}

		if (promotion.products && promotion.products[0]?.productImages[0]?.resolutions) {
			return promotion.products[0]?.productImages[0]?.resolutions;
		}

		return {
			type: 'ImageResolution',
			large: '/image/layout/productPromotionPlaceholder.png',
			medium: '/images/layout/productPromotionPlaceholder.png',
			small: '/images/layout/productPromotionPlaceholder.png',
		};
	}
}


export default PromotionworldGridItem;


