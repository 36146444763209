import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import StoryCategoryListWidget from '../../components/StoryCategoryListWidget/StoryCategoryListWidget';


interface IStoryCategoryListWidgetContainerProps {
	title: string;
	widgetKey: string;

	limit?: number;
	orderBy?: 'rank' | 'createdAt' | 'count';
	orderDir?: 'asc' | 'desc';

	placeholderLimit?: number;
	page?: number;

	routeCallbk: (item: IStoryCategoryType) => { name: string, params: { [key: string]: any } },

	style?: IWidgetStyleType;
}

class StoryCategoryListWidgetContainerInner extends React.Component<IStoryCategoryListWidgetContainerProps & IWithDataProps> {
	public render() {
		const { data, widgetKey, title, routeCallbk } = this.props;

		return (
			<article>
				<StoryCategoryListWidget
					title={title}
					stories={data}
					widgetKey={widgetKey}
					routeCallbk={routeCallbk}
				/>
			</article>
		);
	}
}

export default function StoryCategoryListWidgetContainer(props: IStoryCategoryListWidgetContainerProps) {
	const limit = (props.limit) ? props.limit : 5;
	// const orderBy = ( props.orderBy ) ? props.orderBy : 'rank';
	// const orderDir = ( props.orderDir ) ? props.orderDir : 'desc';

	const RenderComponent = withData(
		StoryCategoryListWidgetContainerInner,
		'storyCategoryListWidgetReducer',
		`story-category-list-widget-${props.widgetKey}`,
		{
			page: props.page,
			size: props.limit,
			// sort: `${orderBy},${orderDir}`,
		},
		{
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
