import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addStoryCategoryListWidgetEntries,
	setStoryCategoryListWidgetErrors,
	STORY_CATEGORY_LIST_WIDGET_FETCH_ENTRIES,
} from './storyCategoryListWidgetActions';

/**
 * Fetch entry list
 */
function* fetchStoryCategoryListWidgets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 5,
	}, action.params);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: '/api/public/v1/widgets/story-categories',
					params: action.params,
				} as IRequestObject);
			},
		);

		yield put(
			addStoryCategoryListWidgetEntries(
				action.queryKey,
				response.getContent('content'),
				response.getMetaData(),
			),
		);
	} catch (e) {
		yield put(setStoryCategoryListWidgetErrors(action.queryKey, e));
	}
}

export function* storyCategoryListWidgetSaga() {
	yield takeEvery(STORY_CATEGORY_LIST_WIDGET_FETCH_ENTRIES, fetchStoryCategoryListWidgets);
}
