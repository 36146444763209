import routeHeaders from '../App/config/routeHeaders';
import appConfig from '../App/config/appConfig';
import fetch from 'cross-fetch';
import express from 'express';
import { entitiesToResolve } from '../App/services/SimpleEntityDetailService';
import routes from '../App/routes';
import { getCharacterizedMetaContent } from '../App/hocs/withRouteMetadata';

/**
 * Find the correct route and named params in route from request
 *
 * @param req
 */
export function getRouteAndObject(req?) {
    const param = {};
    let path = '';

    // if we are on server and have a request object
    if (req) {
        path = req.path;
    }
    // if no req or path given and we are in browser -> get path from url
    else if (!path && !req?.path && typeof window != 'undefined') {
        path = window.location.pathname;
    }

    // make sure we have the correct prefix, without variable distraction
    const pathPrefix = path.split('/')[1];

    // get the correct route
    let theRoute: null | { [key: string]: any } = null;
    for (const routeName of Object.keys(routes)) {
        const route = routes[routeName];

        if (theRoute) {
            continue;
        }

        const routePref = route.split('/')[1];

        // if the route starts with the same prefix
        if (routePref === pathPrefix) {
            theRoute = {
                name: routeName,
                syntax: route,
                prefix: pathPrefix,
                named: {},
            };
        }
    }

    // now take the route apart
    if (theRoute) {
        const routeSplit = theRoute.syntax.split('/');
        const pathSplit = path.split('/');

        for (let indx = 0; indx < routeSplit.length; indx++) {
            const routePathName = routeSplit[indx];

            if (routePathName.substr(0, 1) == ':') {
                const key = routePathName.substr(1, routePathName.length - 1);

                theRoute.named[key] = pathSplit[indx];
            }
        }

        return theRoute;
    }

    return {};
}

/**
 * Defines a map of
 */
const customSlugPathRules = {
    // ppcdest: pathname => {
    //     const splitSlug = pathname.split('/');
    //     var lastCategoryInList = splitSlug[splitSlug.length - 1];
    //     if (!lastCategoryInList) {
    //         var lastCategoryInList = splitSlug[splitSlug.length - 2];
    //     }
    //     return '/ppcdest/' + lastCategoryInList;
    // },
    // ppc: pathname => {
    //     const splitSlug = pathname.split('/');
    //     var lastCategoryInList = splitSlug[splitSlug.length - 1];
    //     if (!lastCategoryInList) {
    //         var lastCategoryInList = splitSlug[splitSlug.length - 2];
    //     }
    //     return '/ppc/' + lastCategoryInList;
    // },
};

/**
 * Tries to find a customized path rule.
 *
 * If found -> it will use the rule provided
 * Else -> otherwise it just returns the path itself
 *
 * @param pathname
 */
export function getPathWithAlterations(pathname: string) {
    // get the first part of slug
    const splitSlug = pathname.split('/');
    var definitionSlugPart = splitSlug[0] || (splitSlug[1] ?? '');

    // get the correct request path
    const path = typeof customSlugPathRules[definitionSlugPart] !== 'undefined' ? customSlugPathRules[definitionSlugPart](pathname) : pathname;

    return path;
}

function fetchSlugs(routerProps: { [key: string]: string }, req): Promise<{ [key: string]: any }> {
    const paramObj = {};
    let paramString = '';

    let i = 0;
    for (const key of Object.keys(entitiesToResolve)) {
        if (routerProps && routerProps[key]) {
            paramObj[entitiesToResolve[key]] = routerProps[key];
            i++;

            paramString += `&${entitiesToResolve[key]}=${routerProps[key]}`;
        }
    }

    if (paramString) {
        paramString = `?${paramString.substring(1)}`;
    }

    const path = getPathWithAlterations(req.path);

    return fetch(appConfig.apiUrl + '/api/public/v1/slugs/' + paramString, {
        headers: {
            'X-Client-Token': appConfig.apiClientToken,
            'Ocp-Apim-Subscription-Key': appConfig.azureApiSubscriptionKey,
            'X-Route-Path': path,
        },
    });
}

/**
 * Adds the correct headers for the route requested (either 404, 301 or 200 with "noindex" enabled/disabled)
 *
 * @param req
 * @param res
 *
 * @return {Promise<{}>}
 *
 * @private
 */
export async function addCorrectResponseHeaders(req: express.Request, res: express.Response) {
    return new Promise((resolve, reject) => {
        // if (process.env.NODE_ENV === 'development') {
        // 	resolve({});
        // }

        const routerProps = getRouteAndObject(req);

        // if the page is inside our routeHeaders config -> apply the values from there
        if (typeof routeHeaders[req.path] !== 'undefined' || typeof routeHeaders['/' + routerProps.prefix + '/'] !== 'undefined') {
            const routeHeaderData = routeHeaders[req.path];

            // set noindex header
            if (routeHeaderData && routeHeaderData.noindex) {
                res.setHeader('X-Robots-Tag', 'noindex');
                resolve({ noindex: true });
            }

            res.setHeader('X-Robots-Tag', 'index');
            resolve({});
        }
        // else -> make a request to our server and check for header infos there
        else if (res && req) {
            try { 
            fetchSlugs(routerProps?.named, req)
                .then(routeResponse => {
                    try {
                        // console.log('----------------------- Route Response: ' + req.path);
                        // console.log('Response: ', routeResponse);
                        // console.log('Headers: ', routeResponse.headers);

                        // if we get a 404 status for this route
                        if (routeResponse.status >= 400 && routeResponse.status < 500) {
                            res.statusCode = 404;

                            resolve({
                                isNotFound: true,
                                notFoundRoute: req.path,
                            });
                        }
                        // if we get a redirect for this route
                        else if (routeResponse.status === 301) {
                            res.statusCode = 301;

                            if (res) {
                                // make sure url has slash at the end
                                let redirectPath = routeResponse.headers.get('x-route-redirect-path');
                                if (redirectPath.substr(-1) !== '/') {
                                    redirectPath = redirectPath + '/';
                                }

                                res.writeHead(301, {
                                    Location: redirectPath,
                                });
                                res.end();
                            }

                            resolve({ end: true });
                        }
                        // else just check if this page should be indexed
                        else {
                            let routeMetadataObject = {};
                            routeResponse.json().then(slugData => {
                                if (routerProps && routerProps.named && Object.values(routerProps.named).length > 0) {
                                    routeMetadataObject = slugData ?? {};
                                }
                                var isNoIndex = routeResponse.headers.get('x-route-noindex') == '1' || routeResponse.headers.get('x-route-noindex') === '1';

                                // add route metadata
                                routeMetadataObject['metaTitle'] = getCharacterizedMetaContent(routeResponse.headers.get('x-route-metatitle'));
                                routeMetadataObject['metaDescription'] = getCharacterizedMetaContent(routeResponse.headers.get('x-route-metadescription'));
                                routeMetadataObject['noIndex'] = isNoIndex;

                                const queryKeyPrefix = routerProps && routerProps.named ? `${routerProps.prefix}-${Object.values(routerProps.named).join('-')}` : 'meta-ssr-route';

                                const newReducerData = {};

                                newReducerData[`${queryKeyPrefix}DEFAULTLOCATIONKEY`] = routeMetadataObject;
                                newReducerData[`${queryKeyPrefix}`] = routeMetadataObject;

                                if (isNoIndex) {
                                    res.setHeader('X-Robots-Tag', 'noindex');

                                    resolve({
                                        noindex: true,
                                        routeMetadataReducer: routeMetadataObject,
                                    });
                                    return;
                                }

                                res.setHeader('X-Robots-Tag', 'index');

                                resolve({
                                    routeMetadataReducer: newReducerData,
                                });
                            }).catch(error => {
                                console.log('PWA SLUG FETCHING ERROR: ' + req.path, error);
                                resolve({});
                            });
                        }
                    } catch (err) {
                        console.log('PWA SLUG FETCHING ERROR JSON WORK: ' + req.path, err);
                        resolve({});
                    }
                })
                .catch(error => {
                    console.log('PWA SLUG FETCHING ERROR: ' + req.path, error);
                    resolve({});
                });

            } catch (err) {
                console.log('PWA SLUG FETCHING ERROR JSON WORK: ' + req.path, err);
                resolve({});
            }
        }
    });
}