import * as React from 'react';
import WidgetBox from '../../../../components/Layout/WidgetBox/WidgetBox';
import WidgetBoxContent from '../../../../components/Layout/WidgetBox/WidgetBoxContent';
import Link from '../../../Base/components/Link/Link';
import { IStoryType } from '../../types/IStoryType';
import StoryListWidgetItem from '../StoryListWidgetItem/StoryListWidgetItem';
import * as styles from './StoryListWidget.scss';

interface IStoryListWidgetProps {
	stories: IStoryType[];
	title?: string;
	widgetKey?: string;
	moreLabel?: string;
	promotionLabel?: string;
	promotionLabelPlural?: string;
	size?: 's' | 'l',
}

class StoryListWidget extends React.Component<IStoryListWidgetProps, {}> {
	public static defaultProps = {
		widgetKey: 'stories-list-widget'
	};

	public render() {
		const { stories, title, widgetKey, size, moreLabel } = this.props;
		
		return (
			<WidgetBox 
				title={title} 
				noBackground={size === 'l'}
			>
				<WidgetBoxContent>
					{stories.map((story: IStoryType) => {
						const route =  {
							name: 'StoryDetailRoute',
							params: {
								story: story.slug,
							},
						};

						return (
							<StoryListWidgetItem
								story={story}
								key={`${widgetKey}-${story.slug}`}
								route={route.name}
								size={size}
								routeParams={route.params}
							/>
						);
					})}

					{( !moreLabel ) ? null :
						<div className={styles.storyListWidgetMoreItem}>
							<Link route="StoryListingRoute">
								<a href="#" className={styles.storyListWidgetMore}>
									<span className={styles.storyListWidgetMoreIcon}>
										{/* <i className="fa fa-chevron-right" /> */}
									</span>
										<span className={styles.storyListWidgetMoreLabel}>
										{moreLabel}
									</span>
								</a>
							</Link>
						 </div>
						}
				</WidgetBoxContent>
			</WidgetBox>
		);
	}
}

export default StoryListWidget;
