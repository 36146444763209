import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addStoryEntries,
	setStoryErrors,
	STORY__FETCH_ENTRIES,
	addStoryEntry,
	STORY__FETCH_ENTRY,
} from './storyActions';

/**
 * Fetch entry list
 */
function* fetchStories(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	const params = afCoreRequest.mergeParams({
		page: 0,
		size: 5,
	}, action.params);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: '/api/public/v1/stories',
					params: action.params,
				} as IRequestObject);
			},
		);

		yield put(
			addStoryEntries(
				action.queryKey,
				response.getContent('content'),
				response.getMetaData(),
				action.options,
			),
		);
	} catch (e) {
		yield put(setStoryErrors(action.queryKey, e));
	}
}

/**
 * Fetch entry details
 */
function* fetchStory(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call((): Promise<RequestMiddlewarePipeline> => {
			return afCoreRequest.getRequest(
				{
					url: `/api/public/v1/stories/${action.params.storySlug}`,
				} as IRequestObject
			);
		});

		yield put(addStoryEntry(
			action.queryKey,
			response.getContent(),
			response.getMetaData(),
			action.options,
		));
	} catch (e) {
		yield put(setStoryErrors(action.queryKey, e));
	}
}

export function* storySaga() {
	yield takeLatest(STORY__FETCH_ENTRY, fetchStory);
	yield takeLatest(STORY__FETCH_ENTRIES, fetchStories);
}
