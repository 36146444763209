import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import StoryListing from '../App/modules/Story/components/StoryListing/StoryListing';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class StoryListingRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await StoryListingRouteProps(state);

		return { reduxData };
	}

	public render() {

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4493482]}
					sitebarId={4493481}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<StoryListing
							queryKey={`stories`}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		StoryListingRoute,
		(props: any) => `stories`,
		{
			metaTitle: "Aktionsfinder Blog » Genuss, Lifestyle & Mode | Aktionsfinder",
			metaDescription: `Aktuelle Shopping News zu Mode, Genuss & Lifestyle auf dem Aktionsfinder-Blog » Tipps & Tricks ✓ Neuigkeiten ✓ Expertenmeinungen ✓ Jetzt entdecken!`,
		} as IRouteMetadataSetterType,
	),
);