import * as React from 'react';
import { MouseEvent } from 'react';
import DataLayerService from '../../../Base/services/DataLayerService';
import { ILeafletPageRegionType } from '../../types/ILeafletPageRegionType';
import { ILeafletPageType } from '../../types/ILeafletPageType';
import { ILeafletType } from '../../types/ILeafletType';
import * as pageWrapperStyles from './LeafletViewerPageWrapper.scss';
import * as styles from './LeafletPageRegion.scss';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import Link from '../../../Base/components/Link/Link';
import { isBrowser } from '../../../../../boilerplate/razzle/razzleUtils';
import { withRouter, RouterProps } from 'react-router';
import LeafletExternalTracker from './LeafletExternalTracker';
import { getClickoutGatewayUrl } from '../../../../utils/clickoutGatewayGenerator';
import { LeafletViewerType } from '../../hocs/withLeaflet';
import Icon from '../../../../modules/Base/components/Icon/Icon';
import { trackPromotionClickout } from '../../../Promotion/utils/trackPromotionClickout';

declare const process: any;

interface ILeafletPageRegionPropsType {
	region: ILeafletPageRegionType;
	leaflet: ILeafletType;
	page: ILeafletPageType;
	flashTime: number;
	pageNumber: number;
	leafletViewerType: LeafletViewerType;
}

interface ILeafletPageRegionStateType {
	flash: boolean;
	showExternalTracking: boolean;
}

class LeafletPageRegion extends React.Component<ILeafletPageRegionPropsType & RouterProps, ILeafletPageRegionStateType> {
	public static defaultProps = {};
	public flashInId: Timer;
	public flashOutId: Timer;

	private externalTrackingTimeout;

	constructor(props: ILeafletPageRegionPropsType) {
		super(props);

		this.state = {
			flash: false,
			showExternalTracking: false,
		};
	}

	public componentDidMount() {
		if (isBrowser()) {
			// enable the flash
			this.flashInId = setTimeout(() => {

				this.setState({
					flash: true,
				}, () => {
					// disable the flash again
					this.flashOutId = setTimeout(() => {
						this.setState({
							flash: false,
						})
					}, 1000);
				});

			},
				(this.props.flashTime));
		}
	}

	public componentWillUnmount(): void {
		clearTimeout(this.flashInId);
		clearTimeout(this.flashOutId);

		if (this.externalTrackingTimeout) {
			clearTimeout(this.externalTrackingTimeout);
		}
	}

	public render() {
		const { leaflet, region, pageNumber } = this.props;
		const { flash, showExternalTracking } = this.state;

		const linkSize = {
			left: `${region.x * 100}%`,
			top: `${region.y * 100}%`,
			// width: '40px',
			// height: '40px',
			width: `${region.width * 100}%`,
			height: `${region.height * 100}%`,
		};

		// style for intern links
		const regionInternClasses = `${styles.regionIntern} ${pageWrapperStyles.region}`;

		// style for extern links
		const regionClasses = `${styles.region} ${pageWrapperStyles.region}`;

		// get correct clickout url
		let clickoutUrl = (region.clickoutUrl) ? region.clickoutUrl : null;
		if (!clickoutUrl && region.promotion && region.promotion.clickoutUrl) {
			clickoutUrl = region.promotion.clickoutUrl;
		}


		if (region.promotion && (!leaflet.directLinkClickouts || !clickoutUrl)) {
			const internalRoute = this.getInternalLinkRoute();

			return (
				<span
					className={`${regionInternClasses} ${(flash) ? styles.regionFlash : ''}`}
					style={linkSize}
				>
					<Link
						route={internalRoute.name}
						params={internalRoute.params}
					>
						<a
							onClick={this.trackClickOut}
						>
							<span className={`${regionInternClasses} ${(region) ? styles.iconWrap : ''}`}>
								<Icon icon="info" className={`${regionInternClasses} ${(region) ? styles.icon : ''}`} />
							</span>

							&nbsp;
						</a>
					</Link>
				</span>
			);
		}


		return (
			<span
				className={`${regionClasses} ${(flash) ? styles.regionFlash : ''}`}
				style={linkSize}
			>
				<a
					id={`region-direct-link-${region.id}`}
					style={{ display: 'none' }}
					href={getClickoutGatewayUrl(clickoutUrl)}
					target="_blank"
					rel="nofollow noopener noreferrer"
				>
					&nbsp;
				</a>

				<a
					href={getClickoutGatewayUrl(clickoutUrl)}
					target="_blank"
					rel="nofollow noopener noreferrer"
					onClick={(e: MouseEvent<HTMLAnchorElement>) => {
						this.openClickOutUrl(clickoutUrl, e);
					}}
				>
					&nbsp;
				</a>

				{showExternalTracking && <LeafletExternalTracker
					event="CLICKOUT_URL"
					leaflet={leaflet}
					pageId={pageNumber}
					clickoutUrl={clickoutUrl}
				/>
				}
			</span>
		);
	}

	/**
	 * If we have an external tracking, we need to show the external Tracking component for a few seconds to let it load and only then go to the target page.
	 * Since we can't wait with the normal redirect, we do a little trick, by loading the component, then have a timeout and then trigger a click event on another link.
	 */
	private openClickOutUrl = (clickoutUrl: string, e: MouseEvent<HTMLAnchorElement>) => {
		const { leaflet, region } = this.props;
		const regionDirectLinkId = `region-direct-link-${region.id}`;

		this.trackExternalClickOut(clickoutUrl);

		// if no external clickout links available -> don't stop
		if (!leaflet.externalTracking || leaflet.externalTracking.length === 0) {
			return;
		}

		e.preventDefault();

		this.setState({ showExternalTracking: true }, () => {
			this.externalTrackingTimeout = setTimeout(() => {
				document.getElementById(regionDirectLinkId).click();

				this.setState({ showExternalTracking: false });
			}, 300);
		});
	}

	/**
	 * Returns the internal link route
	 */
	private getInternalLinkRoute() {
		const { region } = this.props;

		if (region.promotion) {
			return {
				name: 'PromotionDetailRoute',
				params: {
					promotion: region.promotion.slug,
				}
			}
		}

		return {
			name: '',
			params: {},
		};
	}

	/**
	 * Track click out
	 */
	private trackClickOut = () => {
		const { leaflet, region, page, pageNumber, history, leafletViewerType } = this.props;

		if (region.promotion) {
			const promotion = region.promotion;

			// TODO: remove if really not needed anymore
			// let promotionEventType;
			// switch (region.promotion.type) {
			// 	case 'new_price': promotionEventType = 'CLICKOUT_PRICE_PROMOTION'; break;
			// 	case 'product': promotionEventType = 'CLICKOUT_PRODUCT_PROMOTION'; break;
			// 	case 'assortment': promotionEventType = 'CLICKOUT_ASSORTMENT_PROMOTION'; break;
			// 	case 'voucher': promotionEventType = 'CLICKOUT_VOUCHER_PROMOTION'; break;
			// }
			
			// if (promotionEventType) {
			// 	InternalTrackingService.track(promotionEventType, {
			// 		leafletId: leaflet.id,
			// 		leafletPageId: page.id,
			// 		promotionId: promotion.id,
			// 		targetUrl: region.clickoutUrl,
			// 		leafletSlug: leaflet.slug,
			// 	});
			// }

			InternalTrackingService.track('LEAFLET_TO_PROMOTION_CLICK', {
				leafletId: leaflet.id,
				leafletPageId: page.id,
				promotionId: promotion.id,
				targetUrl: region.clickoutUrl,
				industry_slug: leaflet.vendorIndustrySlugs?.join(','),
				infopost: leafletViewerType === LeafletViewerType.INFOPOST
			});

			DataLayerService.push('LEAFLET_TO_PROMOTION_CLICK', {
				leafletId: leaflet.id,
				leafletTitle: leaflet.title,
				vendorId: (leaflet.vendor) ? leaflet.vendor.id : (leaflet.vendorId || ''),
				vendorTitle: (leaflet.vendor) ? leaflet.vendor.name : (leaflet.vendorName || ''),
				pageNumber: pageNumber,
				targetUrl: region.clickoutUrl,
				promotionId: promotion.id,
				promotionTitle: promotion.fullDisplayName,
				brandId: (promotion.product && promotion.product.brand) ? promotion.product.brand.id : '',
				brandTitle: (promotion.product && promotion.product.brand) ? promotion.product.brand.name : '',
				industrySlug: leaflet.vendorIndustrySlugs?.join(','),
			});
		}

		if (history.location.hash !== location.hash) {
			history.replace(location.hash);
		}
	}

	/**
	 * Track click out
	 */
	private trackExternalClickOut = (clickoutUrl) => {
		const { leaflet, leafletViewerType, page, pageNumber, region } = this.props;

		trackPromotionClickout(
			region.promotion,
			region.clickoutUrl,
			leaflet,
			page,
			leafletViewerType,
			pageNumber,
		);
	}
}

export default withRouter(LeafletPageRegion);
