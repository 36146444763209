import * as React from 'react';
import * as styles from './FutherLeafletsPage.scss';
import Button from '../../../../components/General/Button/Button';
import { ILeafletType } from '../../types/ILeafletType';
import LeafletItem from '../LeafletItem/LeafletItem';
import LeafletImagePlaceholder from '../LeafletImagePlaceholder/LeafletImagePlaceholder';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Datespan from '../../../Base/components/Datespan/Datespan';
import HumanReadableDate from '../../../Base/components/HumanReadableDate/HumanReadableDate';
import { DateTime } from 'luxon';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import LeafletService from '../../services/LeafletService';
import LeafletRelativeValidDate from '../LeafletRelativeValidDate/LeafletRelativeValidDate';

interface IFutherLeafletsPagePropsType {
	leaflets: ILeafletType[];
}

class FutherLeafletsPage extends React.Component<IFutherLeafletsPagePropsType, {}> {
	public componentDidMount() {
	  const { leaflets } = this.props;
	  const firstLeaflet = leaflets[0];

	  if (!firstLeaflet) {
		  return;
	  }

	  const expired = DateTime.local().startOf('day') > DateTime.fromISO(firstLeaflet.validTo);
  
	  // don't track it if it is expired
	  if (expired || !firstLeaflet?.id) {
		return;
	  }
  
	  InternalTrackingService.batchTrack(`leaflet-impression-${firstLeaflet.id}`, 'LEAFLET_IMPRESSION', {
		industry_slug: firstLeaflet.vendorIndustrySlugs?.join(','),
		leafletId: firstLeaflet.id,
		vendorId: firstLeaflet.vendorId,
	  });
	}

	public render() {
		const { leaflets } = this.props;
		// console.log(leaflets);
		const firstLeaflet = leaflets?.[0];

		if (!firstLeaflet) {
			return (
				<div className={styles.FutherLeafletsPageWrapper}>
					<div className={styles.FutherLeafletsPageContent}>
						 &nbsp;
					</div>
				</div>
			);
		}

		return (
			<div className={styles.FutherLeafletsPageWrapper}>
				<div className={styles.FutherLeafletsPageContent}>

					<h3>Weitere Prospekte findest du auf:</h3>
					<div className={styles.mobileAppWidget}>
						<a href="https://1aktionsfinder.qr1.at/9osucw" target="_blank">
							<img src={require('./afapp-ad.png')} alt="Installiere die Aktionsfinder App" />
						</a>
					</div>

					<h3>Nächstes Prospekt</h3>
					<div className={styles.TopLeafletWrapper}>
						<div className={styles.TopLeafletImage}>
							{(!firstLeaflet.coverPage || !firstLeaflet.coverPage.resolutions)
								?
								<LeafletImagePlaceholder alt={firstLeaflet.title} />
								:
								<ImageEnhanced
									source={firstLeaflet.coverPage.resolutions}
									size={'m'}
									alt={firstLeaflet.title}
								>
									<LeafletImagePlaceholder alt={firstLeaflet.title} />
								</ImageEnhanced>
							}

							<HumanReadableDate
								from={firstLeaflet.validFrom}
								to={firstLeaflet.validTo}
								outputIfEnded="abgelaufen"
								type="short"
								className={styles.productPromotionExtraTime}
							/>
						</div>

						<div className={styles.TopLeafletInfo}>
							<div className={styles.TopLeafletInfoInner}>
								<span className={`${styles.TopLeafletContent}`}>
									<span className={`${styles.TopLeafletLabel}`} title={firstLeaflet.title}>
										<span className={`${styles.leafletTitle}`}>
											{firstLeaflet.title}
										</span>
									</span>
									<span className={`${styles.TopLeafletValid}`}>
										<time>
											{firstLeaflet.onlyRelativeDate ?
												<LeafletRelativeValidDate
													validFrom={firstLeaflet.validFrom}
													validTo={firstLeaflet.validTo}
												/>
												:
												<Datespan
													from={firstLeaflet.validFrom}
													to={firstLeaflet.validTo}
												/>
											}
										</time>
									</span>
								</span>
								<Button
										theme="primary"
										size="l"
										customClassName={styles.TopLeafletButton}
										href={LeafletService.generateLeafletHref(firstLeaflet)} // Hint: Made because React router doesn't update the page after using normal routing and then clicking "Back" in browser.
										fullWidth={true}
									>
									<b>Ansehen</b>
								</Button>
							</div>
						</div>
					</div>

					<h3 className={styles.leafletGroupTitle}>Ähnliche Prospekte</h3>

					<div className={`${styles.leafletGroup} ${styles.leafletGroupColumns3}`}>
						{
							leaflets.slice(1).map((leaflet) => (
								<React.Fragment key={`promotion-leaflet-grid-${leaflet.slug}`}>
									<div className={`${styles.leafletGroupItem}`}>
										<div className={`${styles.leafletGroupItemInner}`}>
											<LeafletItem
												key={`leaflet-grid-item-${leaflet.slug}`}
												leaflet={leaflet}
												size="s"
												href={LeafletService.generateLeafletHref(leaflet)} // Hint: Made because React router doesn't update the page after using normal routing and then clicking "Back" in browser.
											/>
										</div>
									</div>
								</React.Fragment>
							))
						}
					</div>
				</div>
			</div>
		);
	}
}

export default FutherLeafletsPage;