import * as React from 'react';
import ContentBox from '../../../../components/Layout/PageWrapper/ContentBox';
import Link from '../../../Base/components/Link/Link';
import { IStoryType } from '../../types/IStoryType';
import StoryCardItem from '../StoryCardItem/StoryCardItem';
import * as styles from './StoryCardWidget.scss';
import Pagination from '../../../../components/Layout/Pagination/Pagination';
import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';


interface IStoryCardWidgetProps {
    stories: IStoryType[];
    title?: string;
    widgetKey?: string;
    moreLabel?: string;
    moreRoute?: string;
    promotionLabel?: string;
    promotionLabelPlural?: string;
    columns?: '2' | '3';
    firstCardFullWidth?: boolean;
    boxed?: boolean;

    style?: string;
    enablePagination?: boolean;
    metadata?: IQueryMetaDataType,
    fetchMore?: (queryKey: string) => void;
    queryKey?: string;
    children?: React.ReactNode;
    titleLevel?: 'h1' | 'h2' | 'h3' | 'h4';
}

class StoryCardWidget extends React.Component<IStoryCardWidgetProps, {}> {
    public static defaultProps = {
        widgetKey: 'stories-card-widget',
        columns: '3',
    };

    public render() {
        const {
            stories,
            title,
            widgetKey,
            moreLabel,
            moreRoute,
            columns,
            firstCardFullWidth,
            boxed,
            fetchMore,
            metadata,
            queryKey,
            children,
            titleLevel,
            enablePagination
        } = this.props;

        let className = `${styles.storyCardWidgetItemsContainer} ${styles[ 'storyCardWidgetItemsContainerColumns-' + columns ]}`;

        if (firstCardFullWidth) {
            className += ` ${styles.storyCardFirstCardFullWidth}`;
        }

        if (boxed) {
            className += ` ${styles.storyCardWidgetBoxed}`;
        }

        if (stories.length === 0) {
            return children;
        }

        return (
            <ContentBox title={title} titleLevel={titleLevel}>

                <React.Fragment>

                    <Pagination
                        fetchMore={fetchMore}
                        metadata={metadata}
                        queryKey={queryKey}
                        enablePagination={enablePagination}
                    >
                        <div className={className}>

                            {stories.map((story: IStoryType, storyIndex) => {
                                return (
                                    <StoryCardItem
                                        story={story}
                                        key={`${widgetKey}-${story.slug}`}
                                        columns={columns}
                                        firstCardFullWidth={firstCardFullWidth}
                                        storyIndex={storyIndex}
                                        boxed={boxed}
                                    />
                                );
                            })}
                        </div>

                    </Pagination>

                    {!moreLabel ? null : (
                        <div className={styles.storyCardWidgetMoreItem}>
                            <Link route={moreRoute + ''}>
                                <a className={styles.storyCardWidgetMore}>
									<span
                                        className={
                                            styles.storyCardWidgetMoreLabel
                                        }
                                    >
										{moreLabel}
									</span>
                                </a>
                            </Link>
                        </div>
                    )}
                </React.Fragment>
            </ContentBox>
        );
    }
}

export default StoryCardWidget;
