import * as React from 'react';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import PromotionsAggregatedByVendorsWidgetContainer
	from '../../../Promotion/containers/PromotionsAggregatedByVendorsWidget/PromotionsAggregatedByVendorsWidgetContainer';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import { IPromotionsAggregatedByVendorType } from '../../../Promotion/types/IPromotionsAggregatedByVendorType';
import TagCloudWidgetContainer from '../../../Tag/containers/TagCloudWidget/TagCloudWidgetContainer';
import LeafletGridContainer from '../../containers/LeafletGrid/LeafletGridContainer';
import LeafletsAggregatedByStatesWidgetContainer
	from '../../containers/LeafletsAggregatedByStatesWidget/LeafletsAggregatedByStatesWidgetContainer';
import { ILeafletsAggregatedByStateType } from '../../types/ILeafletsAggregatedByStateType';
import LeafletsAggregatedByProductGroupsWidgetContainer
	from '../../containers/LeafletsAggregatedByProductGroupsWidget/LeafletsAggregatedByProductGroupsWidgetContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';
import H1 from '../../../../components/Layout/H1/H1';
import StaticWidgetContainer from '../../../Base/containers/StaticWidget/StaticWidgetContainer';
import { FavouriteLeafletsByVendor } from '../../../Base/data/staticWidgetData';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import * as styles from './LeafletListingPage.scss';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import { ReactNode } from 'react';
import CarouselContainer from '../../../Carousel/containers/CarouselContainer';

class LeafletListingPage extends React.Component<IWithRouteMetadataType, { contentBreaks: { [key: string]: ReactNode } }> {
	public static defaultProps = {};

	constructor(props) {
		super(props);

		this.state = {
			contentBreaks: {
				4: (
					<div className={styles.mobile}>
						<CarouselContainer />
					</div>
				),
				5: (
					<AdItem
						mobileAdId={4403478}
						maxHeight={250}
						type="content"
						title="Mobile Rectangle 1"
					/>
				),
				6: (
					<div className={styles.desktop}>
						<CarouselContainer />
					</div>
				),
				12: (
					<div className={`${styles.desktop} ${styles.newsletterWrapper}`}>
						<AppMainColBox boxed={true}>
							<NewsletterSignupComponent />
						</AppMainColBox>
					</div>
				),
				13: (
					<AdItem
						mobileAdId={4403477}
						maxHeight={250}
						type="content"
						title="Mobile Rectangle 2"
					/>
				)
			},
		}
	}

	// TRANSLATE
	public render() {
		const { routeMetadata } = this.props;

		return (

			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<div>
					<AdItem
						desktopAdId={4403489}
						maxHeight={250}
						type="billboard"
					/>
				</div>

				<H1>{routeMetadata.pageTitle}</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<LeafletGridContainer
								enablePagination={true}
								gridKey={routeMetadata.queryKey}
								columns="3"
								limit={18}
								pushRouteMetadata={true}
								includeAll={false}
								orderBy="rank"
								orderDir="desc"
								contentBreaks={this.state.contentBreaks}
							>
								<NoDataError />
							</LeafletGridContainer>

							{/* <p>&nbsp;</p>
						<AppMainColBox boxed={true}> */}

						</AppMainColBox>

					</AppMainCol>

					<AppSideCol boxed={false}>
						<div>

							<PromotionsAggregatedByVendorsWidgetContainer
								widgetKey={`${routeMetadata.queryKey}-leaflets-vendors-widget`}
								title={`Prospekte nach Händler`}
								limit={10}
								type="leaflets"
								routeCallbk={this.routerCallbkVendorListWidget}
								promotionLabel="Prospekt"
								promotionLabelPlural="Prospekte"
								orderBy="promotionCount"
								orderDir="desc"
							/>

							<LeafletsAggregatedByProductGroupsWidgetContainer
								widgetKey={`${routeMetadata.queryKey}-product-group-widget`}
								title="Beliebte Produktkategorien"
								limit={60}
								placeholderLimit={5}
								routeCallbk={LeafletListingPage.routerCallbkProductGroupListWidget}
							/>

							<AdItem
								desktopAdId={4403488}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<TagCloudWidgetContainer
								title="Beliebte Themen"
								widgetKey={`${routeMetadata.queryKey}-tag-widget`}
								routeCallbk={LeafletListingPage.routerCallbkTagCloudWidget}
							/>

							<LeafletsAggregatedByStatesWidgetContainer
								title="Prospekte nach Bundesland"
								widgetKey={`${routeMetadata.queryKey}-states-widget`}
								limit={30}
								placeholderLimit={9}
								routeCallbk={LeafletListingPage.routerCallbkStateListWidget}
							/>

							<PromotionsAggregatedByVendorsWidgetContainer
								title="Gutscheine nach Händler"
								widgetKey={`${routeMetadata.queryKey}-vendors-widget`}
								limit={8}
								placeholderLimit={8}
								promotionLabel="Gutschein"
								promotionLabelPlural="Gutscheine"
								routeCallbk={LeafletListingPage.routerCallbkVendorListWidget}
								fetchVoucherProductPromotions={true}
							/>

							<StaticWidgetContainer
								title="Beliebte Händler-Prospekte"
								data={FavouriteLeafletsByVendor}
								widgetKey="favourite-leaflet-by-vendor-leaflet-listing"
							/>

							{/* <StoryListWidgetContainer
								title='Beliebte Stories'
								widgetKey={`${routeMetadata.queryKey}-stories`}
								moreLabel="Alle Stories"
								limit={3}
							/> */}

							<MobileAppWidget />

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Prospekte',
			} as IBreadcrumbItemType
		];
	}

	/**
	 * Defines the route the product group list widget should be routed towards
	 * @param item
	 */
	private static routerCallbkProductGroupListWidget(item: IPromotionsAggregatedByProductGroupType) {  // TODO: remove this text) {
		return {
			name: 'LeafletsInProductGroupRoute',
			params: {
				productGroup: item.group.slug, // TODO: remove this comment
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private static routerCallbkStateListWidget(item: ILeafletsAggregatedByStateType) {
		return {
			name: 'LeafletsInStateRoute',
			params: {
				state: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private static routerCallbkVendorListWidget(item: IPromotionsAggregatedByVendorType) {
		return {
			name: 'VouchersByVendorRoute',
			params: {
				vendor: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the tag list widget should be routed towards
	 *
	 * @param item
	 */
	private static routerCallbkTagCloudWidget(item: ITagWidgetItemType) {
		return {
			name: 'TagDetailRoute',
			params: {
				tag: item.group.slug,
			},
		};
	}

	/**
	 * Defines the route the vendors list widget should be routed towards
	 */
	private routerCallbkVendorListWidget = (item: IPromotionsAggregatedByVendorType) => {

		return {
			name: 'LeafletsByVendorRoute',
			params: {
				vendor: item.group.slug,
			},
		};
	};
}

export default LeafletListingPage;
