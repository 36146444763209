import * as React from 'react';
import { ReactNode } from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import { IImageType } from '../../../Promotion/types/IProductType';
import { IStoryType } from '../../types/IStoryType';
import * as styles from './StoryCardItem.scss';

interface IStoryCardItemProps {
	story: IStoryType;
	children?: ReactNode;
	subtitle?: string;
	columns?: '2' | '3';
	firstCardFullWidth?: boolean;
	storyIndex?: number;
	boxed?: boolean;
}

class StoryCardItem extends React.Component<IStoryCardItemProps> {
	public static defaultProps = {};

	public render() {
		const { story, columns, firstCardFullWidth, storyIndex, boxed } = this.props;

		let className = `${styles.storyCardItem} ${styles['storyCardItemColumns-' + columns]}`;

		const mainImage = this.getMainImage();

		if (firstCardFullWidth && storyIndex === 0) {
			className += ` ${styles.storyCardFirstItem}`;
		}

		if (boxed) {
			className += ` ${styles.storyCardBoxed}`;
		}

		return (
			<Link route="StoryDetailRoute" params={{ story: story.slug }}>
				<a className={className}>
					<span className={styles.storyCardItemImageContainer}>
						{mainImage ? (
							<ImageEnhanced
								source={mainImage.resolutions}
								className={styles.storyCardItemImage}
								alt={mainImage.altText}
								size="m"
							/>
						) : (
								<ProductImagePlaceholder alt={story.title} />
							)}
					</span>

					<span className={styles.storyCardItemTextContainer}>
						{!story.title ? null : (
							<span className={styles.storyCardItemTitle}>
								{story.title}
							</span>
						)}

						{!story.intro ? null : (
							<span className={styles.storyCardItemContent}>
								{story.intro}
							</span>
						)}

						{!story.storyCategory ? null : (
							<span className={styles.storyCardItemCategory}>
								{story.storyCategory.title}
							</span>
						)}
					</span>
				</a>
			</Link>
		);
	}

	/**
	 * Returns the main image we are using to display
	 *
	 * @return {IImageType}
	 */
	private getMainImage(): IImageType | undefined {
		const { story } = this.props;

		if (!story || !story.storyImages || !story.storyImages[0]) {
			return;
		}

		return story.storyImages[0];
	}
}

export default StoryCardItem;
