import { all, fork } from 'redux-saga/effects';
import { homepageSaga } from './components/General/Homepage/store/Homepage/homepageSaga';
import { accountSaga } from './modules/Account/store/Account/accountSaga';
import { verifyMailSaga } from './modules/Account/store/VerifyMail/verifyMailSaga';
import { routeMetadataSaga } from './modules/Base/store/RouteMetadata/routeMetadataSaga';
import { bookmarkSaga } from './modules/Bookmark/store/Bookmark/bookmarkSaga';
import { promotionBookmarkSaga } from './modules/Bookmark/store/PromotionBookmark/promotionBookmarkSaga';
import { shoppingNoteSaga } from './modules/Bookmark/store/ShoppingNote/shoppingNoteSaga';
import { vendorBookmarkSaga } from './modules/Bookmark/store/VendorBookmark/vendorBookmarkSaga';
import { brandSaga } from './modules/Brand/store/Brand/brandSaga';
import { brandGroupedSaga } from './modules/Brand/store/BrandGrouped/brandGroupedSaga';
import { brandWidgetSaga } from './modules/Brand/store/BrandWidget/brandWidgetSaga';
import { productGroupSaga } from './modules/Category/store/ProductGroup/productGroupSaga';
import { productGroupWidgetSaga } from './modules/Category/store/ProductGroupWidget/productGroupWidgetSaga';
import { contentFragmentSaga } from './modules/ContentFragment/store/ContentFragment/contentFragmentSaga';
import { leafletSaga } from './modules/Leaflet/store/Leaflet/leafletSaga';
import { leafletGroupedSaga } from './modules/Leaflet/store/LeafletGrouped/leafletGroupedSaga';
import { leafletPageSaga } from './modules/Leaflet/store/LeafletPage/leafletPageSaga';
import { leafletsAggregatedByProductGroupsWidgetSaga } from './modules/Leaflet/store/LeafletsAggregatedByProductGroupsWidget/leafletsAggregatedByProductGroupsWidgetSaga';
import { leafletsAggregatedByStatesWidgetSaga } from './modules/Leaflet/store/LeafletsAggregatedByStatesWidget/leafletsAggregatedByStatesWidgetSaga';
import { leafletWidgetSaga } from './modules/Leaflet/store/LeafletWidget/leafletWidgetSaga';
import { confirmLotteryRegistrationSaga } from './modules/Lottery/store/ConfirmLotteryRegistration/confirmLotteryRegistrationSaga';
import { lotteryInfoSaga } from './modules/Lottery/store/LotteryInfo/lotteryInfoSaga';
import { lotteryPaymentSaga } from './modules/Lottery/store/LotteryPayment/lotteryPaymentSaga';
import { confirmNewslettersSaga } from './modules/Newsletter/store/ConfirmNewsletters/confirmNewslettersSaga';
import { productPromotionSaga } from './modules/Promotion/store/ProductPromotion/productPromotionSaga';
import { productPromotionGroupedSaga } from './modules/Promotion/store/ProductPromotionGrouped/productPromotionGroupedSaga';
import { productPromotionInOtherShopSaga } from './modules/Promotion/store/ProductPromotionInOtherShop/productPromotionInOtherShopSaga';
import { promotionsAggregatedByBrandsWidgetSaga } from './modules/Promotion/store/PromotionsAggregatedByBrandsWidget/promotionsAggregatedByBrandsWidgetSaga';
import { promotionsAggregatedByCitysWidgetSaga } from './modules/Promotion/store/PromotionsAggregatedByCitysWidget/promotionsAggregatedByCitysWidgetSaga';
import { promotionsAggregatedByProductGroupsWidgetSaga } from './modules/Promotion/store/PromotionsAggregatedByProductGroupsWidget/promotionsAggregatedByProductGroupsWidgetSaga';
import { promotionsAggregatedByStatesWidgetSaga } from './modules/Promotion/store/PromotionsAggregatedByStatesWidget/promotionsAggregatedByStatesWidgetSaga';
import { promotionsAggregatedByVendorsWidgetSaga } from './modules/Promotion/store/PromotionsAggregatedByVendorsWidget/promotionsAggregatedByVendorsWidgetSaga';
import { mostUsedQueriesSaga } from './modules/Search/store/mostUsedQueries/mostUsedQueriesSaga';
import { mixedSearchSaga } from './modules/Search/store/mixedSearch/mixedSearchSaga';
import { searchPromotionSaga } from './modules/Search/store/searchPromotion/searchPromotionSaga';
import { searchShopSaga } from './modules/Search/store/searchShop/searchShopSaga';
import { shoppingCenterSaga } from './modules/ShoppingCenter/store/ShoppingCenter/shoppingCenterSaga';
import { shoppingCenterAggregatedByStatesWidgetSaga } from './modules/ShoppingCenter/store/ShoppingCenterAggregatedByStatesWidget/shoppingCenterAggregatedByStatesWidgetSaga';
import { shoppingCenterGroupedSaga } from './modules/ShoppingCenter/store/ShoppingCentersGrouped/shoppingCentersGroupedSaga';
import { storySaga } from './modules/Story/store/Story/storySaga';
import { storyCategoryListWidgetSaga } from './modules/Story/store/StoryCategoryListWidget/storyCategoryListWidgetSaga';
import { storyListWidgetSaga } from './modules/Story/store/StoryListWidget/storyListWidgetSaga';
import { tagSaga } from './modules/Tag/store/Tag/tagSaga';
import { tagWidgetSaga } from './modules/Tag/store/TagWidget/tagWidgetSaga';
import { themeworldSaga } from './modules/Themeworld/store/Themeworld/themeworldSaga';
import { themeworldWidgetSaga } from './modules/Themeworld/store/ThemeworldWidget/themeworldWidgetSaga';
import { themeworldTagsSaga } from './modules/Themeworld/store/ThemeworldTags/themeworldTagsSaga';
import { nearestShopSaga } from './modules/Vendor/store/NearestShop/nearestShopSaga';
import { shopSaga } from './modules/Vendor/store/Shop/shopSaga';
import { shopGroupedByStateSaga } from './modules/Vendor/store/ShopGroupedByState/shopGroupedByStateSaga';
import { shopWidgetSaga } from './modules/Vendor/store/ShopWidget/shopWidgetSaga';
import { vendorSaga } from './modules/Vendor/store/Vendor/vendorSaga';
import { vendorWidgetSaga } from './modules/Vendor/store/VendorWidget/vendorWidgetSaga';
import { shoppingListSaga } from './modules/Bookmark/store/ShoppingList/shoppingListSaga';
import { lotteryListSaga } from './modules/Lottery/store/LotteryList/lotteryListSaga';
import { productPromotionStatusSaga } from './modules/Promotion/store/ProductPromotionStatus/productPromotionStatusSaga';
import { promotionworldGroupedSaga, promotionworldSaga } from './modules/Promotionworld/sagas';
import { carouselSaga } from './modules/Carousel/store/Carousel/carouselSaga';
import { mixedEntitySaga } from './modules/MixedEntity/store/mixedEntity/mixedEntitySaga';

// single entry point to start all Sagas at once
export default function* rootSaga() {
    yield all([
        fork(brandSaga),
        fork(brandWidgetSaga),
        fork(themeworldSaga),
        fork(themeworldTagsSaga),
        fork(themeworldWidgetSaga),
        fork(vendorSaga),
        fork(vendorWidgetSaga),
        fork(shopSaga),
        fork(shopWidgetSaga),
        fork(productPromotionSaga),
        fork(productPromotionGroupedSaga),
        fork(tagWidgetSaga),
        fork(leafletWidgetSaga),
        fork(productGroupSaga),
        fork(productGroupWidgetSaga),
        fork(leafletGroupedSaga),
        fork(leafletSaga),
        fork(homepageSaga),
        fork(nearestShopSaga),
        fork(productPromotionInOtherShopSaga),
        fork(leafletsAggregatedByStatesWidgetSaga),
        fork(promotionsAggregatedByBrandsWidgetSaga),
        fork(promotionsAggregatedByVendorsWidgetSaga),
        fork(promotionsAggregatedByStatesWidgetSaga),
        fork(promotionsAggregatedByProductGroupsWidgetSaga),
        fork(leafletsAggregatedByProductGroupsWidgetSaga),
        fork(shopGroupedByStateSaga),
        fork(promotionsAggregatedByCitysWidgetSaga),
        fork(storyCategoryListWidgetSaga),
        fork(storyListWidgetSaga),
        fork(shoppingCenterSaga),
        fork(searchShopSaga),
        fork(mixedSearchSaga),
        fork(searchPromotionSaga),
        fork(mostUsedQueriesSaga),
        fork(leafletPageSaga),
        fork(routeMetadataSaga),
        fork(contentFragmentSaga),
        fork(verifyMailSaga),
        fork(tagSaga),
        fork(accountSaga),
        fork(storySaga),
        fork(shoppingCenterAggregatedByStatesWidgetSaga),
        fork(brandGroupedSaga),
        fork(confirmNewslettersSaga),
        fork(confirmLotteryRegistrationSaga),
        fork(lotteryPaymentSaga),
        fork(bookmarkSaga),
        fork(promotionBookmarkSaga),
        fork(lotteryInfoSaga),
        fork(lotteryListSaga),
        fork(shoppingCenterGroupedSaga),
        fork(vendorBookmarkSaga),
        fork(shoppingNoteSaga),
        fork(shoppingListSaga),
        fork(productPromotionStatusSaga),
        fork(promotionworldSaga),
        fork(promotionworldGroupedSaga),
        fork(carouselSaga),
        fork(mixedEntitySaga),
    ]);
}
