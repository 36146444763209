import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import DataStateService from '../../../../services/DataStateService';
import { IStoryType } from '../../types/IStoryType';
import StoryCardWidget from '../../components/StoryCardWidget/StoryCardWidget';
import StoryCardWidgetSkeleton from '../../components/StoryCardWidget/StoryCardWidgetSkeleton';


interface IStoryListingProps {
	queryKey?: string;
	storyCategorySlug?: string;
	orderBy?: 'relevance' | 'createdAt' | 'published';
	orderDir?: 'asc' | 'desc';
	children: React.ReactNode;

	vendorSlug?: string;
	productGroupSlug?: string;
	brandSlug?: string;
}

class StoryListingContainerInner extends React.Component<IStoryListingProps & IWithDataProps> {
	public render() {
		const { data, queryKey } = this.props;

		return (
			<StoryCardWidget
				widgetKey={`${queryKey}`}
				stories={data as IStoryType[]}
				columns="2"
				firstCardFullWidth={true}
				{...this.props}
			/>
		);
	}
}

export default function StoryListingContainer(theProps: IStoryListingProps) {

	const props = Object.assign({
		orderBy: 'createdAt',
		orderDir: 'desc',
		limit: 10,
	}, theProps);

	const RenderComponent = withData(
		StoryListingContainerInner,
		'storyReducer',
		`story-listing-page-${props.queryKey}-${DataStateService.getStateKey()}`,
		{
			size: props.limit,
			orderBy: props.orderBy,
			orderDir: props.orderDir,
			category: props.storyCategorySlug,

			product_group: props.productGroupSlug,
			brand: props.brandSlug,
			store_vendor: props.vendorSlug,
		},
		{
			primaryKeyName: 'storySlug',

			LoadingComponent: () => (
				<StoryCardWidgetSkeleton
					columns="2"
					firstCardFullWidth={true}
				/>
			),
		},

	);

	return <RenderComponent {...props} />
}

