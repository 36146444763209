import * as React from 'react';
import * as styles from './MixedEntityItem.scss';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import Icon from '../../../Base/components/Icon/Icon';
import { IMixedEntityType } from '../../types/IMixedEntityType';

interface IMixedEntityStoryItemProps {
	data: IMixedEntityType;
	columns?: '1' | '2' | '3' | '4' | '5';
}

class MixedEntityStoryItem extends React.Component<IMixedEntityStoryItemProps, {}> {
	public render () {
		const { data, columns } = this.props;

		return (
			<Link
				route="StoryDetailRoute"
				params={{
					story: data.slug,
				}}
			>
				<span
					className={`${styles.MixedEntityItemWrapper} ${styles['MixedEntityItemWrapperColumns' + columns]}`}
				>
					<span
						className={`${styles.MixedEntityItemWrapperInner} ${styles.StoryWrapperInner}`}
					>
						<span className={styles.ImageWrapper}>
							<ImageEnhanced
								 className={styles.StoryImage}
								 source={data.image}
								 bgImage={true}
							/>
						</span>

						<span className={styles.Content}>
							<span className={styles.Heading}>
								{data.title}
								{/* // if title is too long -> show fade out */}
								{ (data.title?.length < 55) ? null :
									<span className={styles.HeadingFadeout} />
								}
							</span>
							
							{( !data.storyIntro ) ? null :
							  <span className={styles.Teaser}>
									{data.storyIntro.substr(0, 150)}
									 {( data.storyIntro.length > 150 ) ? <React.Fragment>...</React.Fragment> : null}
								</span>
							}
						</span>

						<span className={styles.ActionBar}>
							<span className={styles.ActionBarShrink}>
								<Icon icon="stories" className={styles.BiggerIcon} />&nbsp;
								Stories
							</span>
						</span>
					</span>
				</span>
			</Link>
		);
	}

}


export default MixedEntityStoryItem;


