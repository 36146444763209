import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import { IWithRouteMetadataType } from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import ThemeworldListWidgetContainer from '../../../Themeworld/containers/ThemeworldListWidget/ThemeworldListWidgetContainer';
import { IThemeworldWidgetType } from '../../../Themeworld/types/IThemeworldWidget';
import StoryCategoryListWidgetContainer from '../../containers/StoryCategoryListWidget/StoryCategoryListWidgetContainer';
import StoryListingContainer from '../../containers/StoryListing/StoryListingContainer';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IStoryListingProps {
	queryKey?: string;
}

class StoryListing extends React.Component<IStoryListingProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	/**
	 * Returns the breadcrumb path for this page
	 */
	public getBreadcrumbs () {
		return [
			{
				label: 'Stories',
			} as IBreadcrumbItemType,
		];
	}


	public render() {
		const { queryKey } = this.props;

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<H1>
					Lifestyle Stories, Infos & Tipps
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<StoryListingContainer
								queryKey={queryKey}
							/>
							
						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<StoryCategoryListWidgetContainer
								title="Kategorien"
								widgetKey="stories-story-categories-widget"
								routeCallbk={this.routerCallbkStoryCategoryListWidgetContainer}
							/>

							<AdItem
								desktopAdId={4493482}
								maxHeight={250}
								type="content"
								title="Medium Rectangle 1"
							/>

							<ThemeworldListWidgetContainer
								widgetKey="stories-themeworld-widget"
								title="Themenwelten"
								routeCallbk={this.routerCallbkThemeworldListWidget}
								limit={10}
								orderBy="rank"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Defines the route the brand list widget should be routed towards
	 */
	private routerCallbkThemeworldListWidget = (item: IThemeworldWidgetType) => {
		return {
			name: 'ThemeworldDetailRoute',
			params: {
				themeworld: item.slug,
			},
		};
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStoryCategoryListWidgetContainer = (item: IStoryCategoryType) => {
		return {
			name: 'StoriesInCategoryRoute',
			params: {
				storyCategory: item.slug,
			},
		};
	}

}

export default StoryListing;
