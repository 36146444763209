import { withRouter } from 'react-router';
import * as React from 'react';
import Footer from '../App/components/Layout/Footer/Footer';
import Header from '../App/components/Layout/Header/Header';
import MainNavigation from '../App/components/Layout/MainNavigation/MainNavigation';
import withRouteMetadata from '../App/hocs/withRouteMetadata';
import StoryCategoryListing from '../App/modules/Story/components/StoryCategoryListing/StoryCategoryListing';
import { withTitlePostfix, withClickbait } from '../App/utils/routeMetadataUtils';
import BaseRoute from './_BaseRoute';
import AdConfig from '../App/components/General/AdConfig/AdConfig';
import FlexWrapper from '../App/components/Layout/Flexbox/FlexWrapper';
import AdItem from '../App/components/General/AdItem/AdItem';


class StoriesInCategoryRoute extends BaseRoute {
	public static async getInitialProps (state: any) {
		const reduxData = await StoriesInCategoryRouteProps(state);

		return { reduxData };
	}
	
	public render () {
		const query = ( this.props.match.params ) ? this.props.match.params : {};

		return (
			<React.Fragment>

				<AdConfig
					desktopIds={[4493482]}
					sitebarId={4493481}
				/>

				<Header />
				<MainNavigation />

				<FlexWrapper>
					<div>
						<StoryCategoryListing
							queryKey={`storyCategory-${query.storyCategory}`}
							storyCategorySlug={`${query.storyCategory}`}
							{...this.props}
						/>

						<Footer />
					</div>

					<AdItem
						desktopAdId={4493481}
						maxHeight={600}
						maxWidth={300}
						type="side"
						className="startBelowMenuBar"
					/>
				</FlexWrapper>

			</React.Fragment>
		);
	}
}

export default withRouter(
	withRouteMetadata(
		StoriesInCategoryRoute,
		(props: any) => `story-category-${props.match.params.storyCategory}`,
		{
			pageTitle: (routeMetadata: IRouteMetadataType) => `Aktuelles aus der Kategorie ${routeMetadata.storyCategory.title}`,
			metaTitle: (routeMetadata: IRouteMetadataType) => 
				withTitlePostfix(`Shopping News zu ${routeMetadata.storyCategory.title}`),
			metaDescription: (routeMetadata: IRouteMetadataType) => 
				withClickbait(`Spannende Artikel & Informationen zur Kategorie ${routeMetadata.storyCategory.title} auf dem Aktionsfinder-Blog » Neuigkeiten, Tipps & Tricks online entdecken. Mehr erfahren!`),
		} as IRouteMetadataSetterType
	)
);