import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import StoryDetail from '../../components/StoryDetail/StoryDetail';
import { IStoryType } from '../../types/IStoryType';
import StoryDetailSkeleton from '../../components/StoryDetail/StoryDetailSkeleton';


interface IStoryDetailProps {
	storySlug: string;
	queryKey: string;
}

class StoryDetailContainerInner extends React.Component<IStoryDetailProps & IWithDataProps> {
	public render() {
		const { data } = this.props;

		return (
			<StoryDetail
				story={data as IStoryType}
			/>
		);
	}
}

export default function StoryDetailContainer(props: IStoryDetailProps) {
	const RenderComponent = withData(
		StoryDetailContainerInner,
		'storyReducer',
		`story-detail-${props.storySlug}-${props.queryKey}`,
		{
			storySlug: props.storySlug,
		},
		{
			isSingleEntry: true,
			primaryKeyName: 'storySlug',
			LoadingComponent: () => <StoryDetailSkeleton />
		}
	);

	return <RenderComponent {...props} />
}

