import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as storyActions from './storyActions';
import { IStoryType } from '../../types/IStoryType';

export type StoryAction = ActionType<typeof storyActions>;

export interface IStoryStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IStoryType };
	readonly queries: { [queryKey: string]: IStoryType[] };
}

export function storyReducer(
	state = baseInitialState as IStoryStateType,
	action: StoryAction,
) {
	const Reducer = new BaseReducer('storyReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}