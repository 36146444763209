import * as React from 'react';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import {IWithRouteMetadataType} from '../../../../types/IWithRouteMetadataType';
import Row from '../../../Base/components/Grid/Row';
import StoryCategoryListWidgetContainer
	from '../../containers/StoryCategoryListWidget/StoryCategoryListWidgetContainer';
import StoryListingContainer from '../../containers/StoryListing/StoryListingContainer';
import {SlugType} from 'App/modules/Base/types/SlugType';
import NoDataError from '../../../../components/Layout/NoDataError/NoDataError';
import AdItem from '../../../../components/General/AdItem/AdItem';


interface IStoryCategoryListingProps {
	storyCategorySlug: SlugType;
	queryKey?: string;
}

class StoryCategoryListing extends React.Component<IStoryCategoryListingProps & IWithRouteMetadataType, {}> {
	public static defaultProps = {};

	/**
	 * Returns the breadcrumb path for this page
	 */
	private getBreadcrumbs() {
		return [
			{
				label: 'Stories',
				route: 'StoryListingRoute'
			},
			{
				label: (this.props.routeMetadata && this.props.routeMetadata.storyCategory) ? this.props.routeMetadata.storyCategory.title : this.props.storyCategorySlug,
			} as IBreadcrumbItemType,
		];
	}

	public render() {
		const {storyCategorySlug, routeMetadata, queryKey} = this.props;

		if (!routeMetadata) {
			return null;
		}

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>
				<H1>
					{routeMetadata.pageTitle}
				</H1>

				<Row>
					<AppMainCol
						boxed={true}
						onTop={true}
					>
						<AppMainColBox nested={true} boxed={true}>

							<StoryListingContainer
								queryKey={queryKey}
								storyCategorySlug={storyCategorySlug}
							>
								<NoDataError/>
							</StoryListingContainer>

						</AppMainColBox>
					</AppMainCol>

					<AppSideCol boxed={true} onTop={true}>
						<div>

							<StoryCategoryListWidgetContainer
								title="Kategorien"
								widgetKey="stories-story-categories-widget"
								routeCallbk={this.routerCallbkStoryCategoryListWidgetContainer}
							/>

							<AdItem
								desktopAdId={4493482}
								maxHeight={250}
								type="content"
								title="Medium Rectangle 1"
							/>

						</div>
					</AppSideCol>
				</Row>

			</PageWrapper>
		);
	}

	/**
	 * Defines the route the state list widget should be routed towards
	 *
	 * @param item
	 */
	private routerCallbkStoryCategoryListWidgetContainer = (item: IStoryCategoryType) => {
		return {
			name: 'StoriesInCategoryRoute',
			params: {
				storyCategory: item.slug,
			},
		};
	}
}

export default StoryCategoryListing;
