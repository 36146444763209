import * as React from 'react';
import withData from '../../../../../boilerplate/redux/hocs/withData';
import { IWithDataProps } from '../../../../../boilerplate/redux/types/IWithDataProps';
import WidgetBoxError from '../../../../components/Layout/WidgetBox/WidgetBoxError';
import WidgetBoxSkeleton from '../../../../components/Layout/WidgetBox/WidgetBoxSkeleton';
import { IWidgetStyleType } from '../../../../types/IWidgetStyleType';
import StoryListWidget from '../../components/StoryListWidget/StoryListWidget';


interface IStoryListWidgetContainerProps {
	title?: string;
	widgetKey: string;
	moreLabel?: string;
	limit?: number;
	placeholderLimit?: number;
	page?: number;
	size?: 's' | 'l';

	style?: IWidgetStyleType;

	vendorSlug?: string;
	productGroupSlug?: string;
	directProductGroupSlug?: string;
	brandSlug?: string;
	industrySlug?: string;
	themeworldSlug?: string;
	shopSlug?: string;
}

class StoryListWidgetContainerInner extends React.Component<IStoryListWidgetContainerProps & IWithDataProps> {

	public static defaultProps = {
		size: 's',
	};

	public render () {
		const { data, widgetKey, title, size, moreLabel } = this.props;

		if (data.length === 0) {
			return null;
		}

		return (
			<StoryListWidget
				title={title}
				stories={data}
				widgetKey={widgetKey}
				size={size}
				moreLabel={moreLabel}
			/>
		);
	}
}

export default function StoryListWidgetContainer (props: IStoryListWidgetContainerProps) {
	const limit = ( props.limit ) ? props.limit : 5;

	const RenderComponent = withData(
		StoryListWidgetContainerInner,
		'storyListWidgetReducer',
		`story-list-widget-${props.widgetKey}`,
		{
			page: props.page,
			size: props.limit,
			store_vendor: props.vendorSlug,
			store: props.shopSlug,

			product_group: props.productGroupSlug,
			direct_product_group: props.directProductGroupSlug,
			brand: props.brandSlug,
			industry: props.industrySlug,
			theme_world: props.themeworldSlug,
		},
		{
			primaryKeyName: 'storySlug',
			
			LoadingComponent: () => (
				<WidgetBoxSkeleton
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
			ErrorComponent: (errorProps: any) => (
				<WidgetBoxError
					errors={errorProps.errors}
					title={props.title}
					rows={(props.placeholderLimit) ? props.placeholderLimit : limit}
					type="item"
					widgetKey={props.widgetKey}
					showValues={{
						title: true,
						subtitle: false,
						special: false,
						extra: false,
					}}
				/>
			),
		}
	);

	return <RenderComponent {...props} />
}
