import * as React from 'react';
import * as styles from './StoryCardWidget.scss';
import ContentBox from '../../../../components/Layout/PageWrapper/ContentBox';
import StoryCardItemSkeleton from '../StoryCardItem/StoryCardItemSkeleton';

interface IStoryCardWidgetSkeletonProps {
    title?: string;
    widgetKey?: string;
    promotionLabel?: string;
    promotionLabelPlural?: string;
    columns?: '2' | '3';
    firstCardFullWidth?: boolean;
}

class StoryCardWidgetSkeleton extends React.PureComponent<IStoryCardWidgetSkeletonProps, {}> {
    public static defaultProps = {
        columns: 3,
        widgetKey: 'stories-card-widget-skeleton'
    };

    public render() {
        const { title, widgetKey, columns, firstCardFullWidth } = this.props;

        let className = `${styles.storyCardWidgetItemsContainer} ${styles['storyCardWidgetItemsContainerColumns-' + columns]}`;

        if (firstCardFullWidth) {
            className += ` ${styles.storyCardFirstCardFullWidth}`
        }

        return (

            <ContentBox title={title}>
                <div>
                    <div className={className}>
                        {[1, 2, 3, 4, 5, 6, 7, 8].map((index) => {

                            return (
                                <StoryCardItemSkeleton
                                    key={`${widgetKey}-${index}`}
                                    columns={columns}
                                    firstCardFullWidth={firstCardFullWidth}
                                    index={index}
                                />

                            );
                        })}
                    </div>
                </div>
            </ContentBox>
        );
    }

}

export default StoryCardWidgetSkeleton;
