import * as React from 'react';
import { ReactNode } from 'react';
import * as styles from './StoryCardItem.scss';
import SkeletonInline from '../../../../components/Layout/Skeleton/SkeletonInline';
import SkeletonBlock from '../../../../components/Layout/Skeleton/SkeletonBlock';

interface IStoryCardItemSkeletonProps {
	children?: ReactNode,
	subtitle?: string,
	columns?: '2' | '3';
	firstCardFullWidth?: boolean;
	index?: number;
}

class StoryCardItemSkeleton extends React.PureComponent<IStoryCardItemSkeletonProps> {
	public static defaultProps = {

	};

	public render() {
		const { columns, firstCardFullWidth, index } = this.props;

		let className = `${styles.storyCardItem} ${styles['storyCardItemColumns-' + columns]}`;

		if (firstCardFullWidth && index === 1) {
			className += ` ${styles.storyCardFirstItem}`
		}

		return (
			<a className={className}>
				<div className={styles.storyCardItemImageContainer}>
				</div>
				<div className={styles.storyCardItemTextContainer}>
					<SkeletonBlock style={{ width: 150, height: 80 }} />
					<div className={styles.storyCardItemContent}>
						<h2 className={styles.storyCardItemTitle}>
							<SkeletonInline />
						</h2>
					</div>
				</div>
			</a>
		)
	}
}

export default StoryCardItemSkeleton;
