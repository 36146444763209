import { DateTime } from 'luxon';
import * as React from 'react';
import { ReactNode } from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import Link from '../../../Base/components/Link/Link';
import ProductImagePlaceholder from '../../../Promotion/components/ProductImagePlaceholder/ProductImagePlaceholder';
import { IImageType } from '../../../Promotion/types/IProductType';
import { IStoryType } from '../../types/IStoryType';
import * as styles from './StoryListWidgetItem.scss';

interface IStoryListWidgetItemProps {
    story: IStoryType,
	children?: ReactNode,
	subtitle?: string,
    route: string,
    size?: 's' | 'l',
	routeParams?: any,
}

class StoryListWidgetItem extends React.Component<IStoryListWidgetItemProps> {
	public static defaultProps = {
		size: 's',
	};

	public render () {
		const { story, route, size, routeParams } = this.props;

        const className = `${styles.storyListWidgetItem} ${styles['storyListWidgetItemSize-'+ size]}`;
        
        const date = DateTime.fromISO(story.visibleFrom).setLocale('de');
        const day = date.toFormat('dd');
        const month = date.monthShort;
        const year = date.year;

		const mainImage = this.getMainImage();

		return (
			<Link
				route={route}
				params={routeParams}
			>
				<a className={className}>
                    
                    <div className={styles.storyListWidgetItemImageContainer}>
	                    {( mainImage ) ?
	                     <ImageEnhanced
		                     source={mainImage.resolutions}
		                     className={styles.storyListWidgetItemImage}
							 alt={mainImage.altText}
							 bgImage={true}
	                     />
	                                   :
	                     <ProductImagePlaceholder
		                     alt={story.title}
	                     />
	                    }
                    </div>

					<div className={styles.storyListWidgetItemTextContainer}>

						{( !story.title ) ? null :
                            <div className={styles.storyListWidgetItemTitle}>{story.title}</div>
                        }

                        {( !story.content || size === 's') ? null :
                            <div className={styles.storyListWidgetItemContent}>
                                <div dangerouslySetInnerHTML={{ __html: story.content }} />
                            </div>
                        }

                        {( !story.storyCategory ) ? null :
                            <div className={styles.storyListWidgetItemCategory}>
                                {story.storyCategory.title}
                            </div>
                        }
                    </div>

                    <div className={styles.storyListWidgetItemDateContainer}>
                        <div className={styles.storyListWidgetItemDate}>{day}</div>
                        <div className={styles.storyListWidgetItemMonthYear}>{month}. {year}</div>
                    </div>			
				</a>
			</Link>
		);
	}

	/**
	 * Returns the main image we are using to display
	 *
	 * @return {IImageType}
	 */
	private getMainImage(): IImageType {
		const { story } = this.props;

		if (!story || !story.storyImages || !story.storyImages[0]) {
			return;
		}

		return story.storyImages[0];
	}
}

export default StoryListWidgetItem;
