import { call, put, takeEvery } from 'redux-saga/effects';
import RequestMiddlewarePipeline from '../../../../../boilerplate/redux/RequestMiddlewarePipeline';
import AfCoreRequest from '../../../../bootstrap/redux-data/AfCoreRequest';
import {
	addStoryListWidgetEntries,
	setStoryListWidgetErrors,
	STORY__LIST_WIDGET_FETCH_ENTRIES,
} from './storyListWidgetActions';

/**
 * Fetch entry list
 */
function* fetchStoryListWidgets(action: any) {
	const afCoreRequest = new AfCoreRequest(action.queryKey);

	try {
		const response = yield call(
			(): Promise<RequestMiddlewarePipeline> => {
				return afCoreRequest.getRequest({
					url: '/api/public/v1/widgets/stories',
					params: action.params,
				} as IRequestObject);
			},
		);

		yield put(
			addStoryListWidgetEntries(
				action.queryKey,
				response.getContent('content'),
				response.getMetaData(),
				action.options,
			),
		);
	} catch (e) {
		yield put(setStoryListWidgetErrors(action.queryKey, e));
	}
}

export function* storyListWidgetSaga() {
	yield takeEvery(STORY__LIST_WIDGET_FETCH_ENTRIES, fetchStoryListWidgets);
}
