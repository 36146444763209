import { ActionType } from 'typesafe-actions';
import BaseReducer, {
	baseInitialState,
} from '../../../../../boilerplate/redux/BaseReducer';
import { IBaseStateType } from '../../../../../boilerplate/redux/types/IBaseStateType';
import * as storyCategoryListWidgetActions from './storyCategoryListWidgetActions';

export type StoryCategoryListWidgetAction = ActionType<typeof storyCategoryListWidgetActions>;

export interface IStoryCategoryListWidgetStateType extends IBaseStateType {
	readonly entryMap: { [queryKey: string]: IStoryCategoryType };
	readonly queries: { [queryKey: string]: IStoryCategoryType[] };
}

export function storyCategoryListWidgetReducer(
	state = baseInitialState as IStoryCategoryListWidgetStateType,
	action: StoryCategoryListWidgetAction,
) {
	const Reducer = new BaseReducer('storyCategoryListWidgetReducer', 'slug');

	return Reducer.extend(state, action, (innerState, innerAction) => {
		switch (innerAction.type) {
			default:
				return innerState;
		}
	});
}