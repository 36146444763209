import * as React from 'react';
import ImageEnhanced from '../../../../components/General/ImageEnhanced/ImageEnhanced';
import H1 from '../../../../components/Layout/H1/H1';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppMainColBox from '../../../../components/Layout/PageGrid/AppMainColBox';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import appConfig from '../../../../config/appConfig';
import InternalTrackingService from '../../../../services/InternalTrackingService';
import Row from '../../../Base/components/Grid/Row';
import Link from '../../../Base/components/Link/Link';
import DataLayerService from '../../../Base/services/DataLayerService';
import NewsletterSignupComponent from '../../../Newsletter/components/Newsletter/NewsletterSignupComponent';
import ProductPromotionContainer
    from '../../../Promotion/containers/ProductPromotionGrid/ProductPromotionGridContainer';
import StoryCardWidgetContainer from '../../containers/StoryCardWidgetContainer/StoryCardWidgetContainer';
import StoryCategoryListWidgetContainer
    from '../../containers/StoryCategoryListWidget/StoryCategoryListWidgetContainer';
import { IStoryType } from '../../types/IStoryType';
import * as styles from './StoryDetail.scss';
import { DateTime } from 'luxon';
import NearestShopWidget from '../../../Vendor/components/NearestShopWidget/NearestShopWidget';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IStoryDetailProps {
    story: IStoryType;
}

const tracked: { [storyId: string]: boolean } = {};

class StoryDetail extends React.Component<IStoryDetailProps, {}> {
    public static defaultProps = {};

    public componentDidMount() {
        // this.trackStoryView(this.props);
    }

    public componentDidUpdate(prevProps: IStoryDetailProps) {
        if (prevProps.story.id !== this.props.story.id) {
            // this.trackStoryView(prevProps);
        }
    }

    public render() {
        const { story } = this.props;

        return (
            <PageWrapper breadcrumbs={this.getBreadcrumbs()}>

                <AdItem
                    desktopAdId={4493483}
                    maxHeight={250}
                    type="billboard"
                />

                <Row>
                    <AppMainCol boxed={true}>
                        <AppMainColBox boxed={true} nested={true}>

                            <React.Fragment>
                                <div className={styles.storyDetailPostedDate}>

                                    <H1 title={story.title} />

                                    <div className={styles.storyDetailPostedDateText}>
                                        Geposted
                                        {!story.storyCategory ||
                                            !story.storyCategory.title ? null : (
                                            <React.Fragment>
                                                <span> in </span>
                                                <Link
                                                    route="StoriesInCategoryRoute"
                                                    params={{
                                                        storyCategory: story.storyCategory.slug,
                                                    }}
                                                >
                                                    <a>
                                                        {story.storyCategory && story.storyCategory.title}
                                                    </a>
                                                </Link>
                                            </React.Fragment>
                                        )}
                                        <span> am {DateTime.fromISO(story.visibleFrom).setLocale('de')
                                            .toFormat('D')}</span>
                                    </div>
                                </div>

                                <div className={styles.storyIntroWrapper}>
                                    {!story.storyImages[0] ? null : (
                                        <ImageEnhanced
                                            className={styles.storyImage}
                                            source={story.storyImages[0].resolutions}
                                            size="m"
                                        />
                                    )}
                                </div>

                                <div
                                    className={styles.storyContent}
                                    dangerouslySetInnerHTML={{ __html: story.content }}
                                />

                                {(!story.vendors[0]) ? null :
                                    <div>
                                        <p>&nbsp;</p>
                                        <NearestShopWidget
                                            vendor={story.vendors[0]}
                                            size="s"
                                        />
                                    </div>
                                }

                                <ProductPromotionContainer
                                    columns={'2'}
                                    onlyNearbyPromotions={true}
                                    limit={4}
                                    enablePagination={false}
                                    title="Tipps der Redaktion"
                                    titleLevel={2}
                                    storySlug={story.slug}
                                    useLeafletRoute={false}
                                />
                            </React.Fragment>

                        </AppMainColBox>

                        <p>&nbsp;</p>

                        <AppMainColBox boxed={true}>
                            <NewsletterSignupComponent />
                        </AppMainColBox>

                        <AppMainColBox nested={true} boxed={true}>

                            <div className={styles.storyWidgetContainer}>
                                <StoryCardWidgetContainer
                                    widgetKey="story-detail-story-cards"
                                    moreLabel="WEITERE ARTIKEL ..."
                                    moreRoute="StoryListingRoute"
                                    title="Das könnte ebenfalls interessant sein"
                                    limit={4}
                                    columns="2"
                                    excludeSlug={story.slug}
                                    enablePagination={false}
                                />

                            </div>

                            <p>&nbsp;</p>

                        </AppMainColBox>

                        <script
                            type="application/ld+json" dangerouslySetInnerHTML={{ __html: this.getSchemaOrgJson() }}
                        />

                    </AppMainCol>

                    <AppSideCol boxed={false}>

                        <div>
                            <StoryCategoryListWidgetContainer
                                title="Kategorien"
                                widgetKey="stories-story-categories-widget"
                                routeCallbk={this.routerCallbkStoryCategoryListWidgetContainer}
                            />

                            <AdItem
                                desktopAdId={4493482}
                                maxHeight={250}
                                maxWidth={300}
                                type="content"
                                title="Medium Rectangle 1"
                            />
                        </div>

                    </AppSideCol>
                </Row>

            </PageWrapper>
        );
    }

    /**
     * Returns the breadcrumb path for this page
     */
    private getBreadcrumbs() {
        const { story } = this.props;

        return [
            {
                label: 'Stories',
                route: 'StoryListingRoute',
            } as IBreadcrumbItemType,
            {
                label: story.title,
            } as IBreadcrumbItemType,
        ];
    }

    /**
     * Defines the route the state list widget should be routed towards
     *
     * @param item
     */
    private routerCallbkStoryCategoryListWidgetContainer = (
        item: IStoryCategoryType,
    ) => {
        return {
            name: 'StoriesInCategoryRoute',
            params: {
                storyCategory: item.slug,
            },
        };
    };

    /**
     * Track view
     */
    private trackStoryView = (props) => {
        const { story } = props;

        if (tracked[story.id]) {
            return;
        }
        tracked[story.id] = true;

        InternalTrackingService.track('STORY_VIEW', {
            storyId: story.id,
        });

        DataLayerService.push('STORY_VIEW', {
            storyId: story.id,
            storyTitle: story.title,
        });
    };

    /**
     * Returns the correct schema org data
     */
    private getSchemaOrgJson(): string {
        const { story } = this.props;

        const image = (story.storyImages && story.storyImages[0]) ? story.storyImages[0].resolutions['large'] : null;

        return JSON.stringify(
            {
                '@context': 'http://schema.org',
                '@type': 'BlogPosting',
                'headline': story.title,
                'mainEntityOfPage': {
                    '@type': 'WebPage',
                    '@id': appConfig.mainDomain,
                },
                'image': {
                    '@type': 'ImageObject',
                    'url': image,
                },
                'author': {
                    '@type': 'Person',
                    'name': 'Aktionsfinder Team',
                },
                'publisher': {
                    '@type': 'Organization',
                    'name': 'Aktionsfinder',
                    'logo': {
                        '@type': 'ImageObject',
                        'url': `${appConfig.mainDomain}/logo_1row.png`,
                        'width': 550,
                        'height': 97,
                    },
                },
                'datePublished': story.visibleFrom,
                'dateModified': story.visibleFrom,
                'description': story.intro,
                'articleBody': story.content,
            },
        );
    }
}

export default StoryDetail;
