/* ### CONSTANTS ### */

import { IQueryMetaDataType } from '../../../../../boilerplate/redux/types/IQueryMetaDataType';
import { IApiErrorType } from '../../../../types/BaseTypes';
import { IStoryType } from '../../types/IStoryType';
// import { IVendorWidgetType } from '../../types/IVendorWidgetType';

export const STORY__FETCH_ENTRIES = '@BasePipeline/storyReducer_FETCH_ENTRIES';
export const STORY__FETCH_ENTRY   = '@BasePipeline/storyReducer_FETCH_ENTRY';
export const STORY__ADD_ENTRIES = '@BasePipeline/storyReducer_ADD_ENTRIES';
export const STORY__ADD_ENTRY = '@BasePipeline/storyReducer_ADD_ENTRY';

export const STORY__SET_ERRORS   = '@BasePipeline/storyReducer_SET_ERRORS';

/* ### ACTIONS ### */

export function fetchStorys (queryKey: string) {
	return {
		type: STORY__FETCH_ENTRIES,
		queryKey,
	};
}

export function addStoryEntries (queryKey: string, entries: IStoryType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: STORY__ADD_ENTRIES,
		queryKey,
		entries,
		metaData,
		options
	};
}

export function addStoryEntry (queryKey: string, entry: IStoryType, metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: STORY__ADD_ENTRY,
		queryKey,
		entry,
		metaData,
	};
}

export function setStoryErrors (queryKey: string, errors: IApiErrorType[], metaData?: IQueryMetaDataType , options?: IFetchOptionType) {
	return {
		type: STORY__SET_ERRORS,
		queryKey,
		errors,
		metaData,
	};
}
