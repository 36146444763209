import * as React from 'react';
import MobileAppWidget from '../../../../components/Layout/MobileAppWidget/MobileAppWidget';
import AppMainCol from '../../../../components/Layout/PageGrid/AppMainCol';
import AppSideCol from '../../../../components/Layout/PageGrid/AppSideCol';
import PageWrapper from '../../../../components/Layout/PageWrapper/PageWrapper';
import Row from '../../../Base/components/Grid/Row';
import ProductPromotionListWidgetContainer from '../../../Promotion/containers/ProductPromotionListWidget/ProductPromotionListWidgetContainer';
import PromotionsAggregatedByBrandsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByBrandsWidget/PromotionsAggregatedByBrandsWidgetContainer';
import PromotionsAggregatedByProductGroupsWidgetContainer from '../../../Promotion/containers/PromotionsAggregatedByProductGroupsWidget/PromotionsAggregatedByProductGroupsWidgetContainer';
import { IPromotionsAggregatedByBrandType } from '../../../Promotion/types/IPromotionsAggregatedByBrandType';
import { IPromotionsAggregatedByProductGroupType } from '../../../Promotion/types/IPromotionsAggregatedByProductGroupType';
import ShopListGroupedByStateContainer from '../../containers/ShopListGroupedByState/ShopListGroupedByStateContainer';
import { IShopGroupedByStateType } from '../../types/IShopGroupedByStateType';
import { IVendorType } from '../../types/IVendorType';
import * as styles from './VendorDetail.scss';
import { SlugType } from '../../../Base/types/SlugType';
import { IBreadcrumbItemType } from '../../../../types/IBreadcrumbItemType';
import VendorAggregatedByCityWrapper from '../VendorAggregatedByCity/VendorAggregatedByCityWrapper';
import DataStateService from '../../../../services/DataStateService';
import CurrentVendorLeafletContainer from '../../../Leaflet/containers/CurrentVendorLeaflet/CurrentVendorLeafletContainer';
import WidgetListItemLinkRow from '../../../../components/Layout/WidgetBox/WidgetListItemLinkRow';
import StoryListWidgetContainer from '../../../Story/containers/StoryListWidget/StoryListWidgetContainer';
import VendorDetailHeader from '../VendorDetailHeader/VendorDetailHeader';
import AdItem from '../../../../components/General/AdItem/AdItem';

interface IVendorProps {
	vendor?: IVendorType;
	vendorSlug: SlugType;
	shopGroupedByStateReducer?: any;
}

class VendorDetail extends React.Component<IVendorProps, {}> {
	public static defaultProps = {};

	public render() {
		const { vendor, vendorSlug } = this.props;
		const vendorName = vendor ? vendor.name : 'Händler';

		const shopList = this.getStateArrayWithStoreCount();

		return (
			<PageWrapper breadcrumbs={this.getBreadcrumbs()}>

				<AdItem
					desktopAdId={4403495}
					maxHeight={250}
					type="billboard"
				/>

				<div>&nbsp;</div>

				<Row>
					<AppMainCol boxed={true} onTop={true}>

						<VendorDetailHeader vendor={vendor} vendorSlug={vendorSlug} />

						<div className={styles.tagCloudWidgetContainer}>
							<VendorAggregatedByCityWrapper data={shopList} vendor={vendorSlug} />
						</div>

						<CurrentVendorLeafletContainer
							vendorSlug={vendorSlug}
							widgetKey={`vendor-leaflet-${vendorSlug}`}
						>
							<div className={styles.actionWrapper}>
								<WidgetListItemLinkRow
									href={`/lv/${vendorSlug}`}
									title={`Zu allen ${vendorName} Prospekten`}
								/>

								<WidgetListItemLinkRow
									href={`/pv/${vendorSlug}`}
									title={`Zu allen ${vendorName} Angeboten`}
								/>

								<WidgetListItemLinkRow
									href={`/apv/${vendorSlug}`}
									title={`Zu allen ${vendorName} Sortimentsrabatten`}
								/>
							</div>
						</CurrentVendorLeafletContainer>

						<ShopListGroupedByStateContainer
							vendorSlug={vendorSlug}
							widgetKey={`vendor-detail-shop-list-${vendorSlug}`}
							titleLabel={`%count% ${vendorName} Markt in %state%`}
							titleLabelPlural={`%count% ${vendorName} Märkte in %state%`}
							moreLabel={`Alle ${vendorName} Märkte in %state%`}
							moreRouteCallbk={this.routerCallbkShopListWidget}
							contentBreaks={{
								1: (
									<AdItem
										mobileAdId={4403482}
										maxHeight={250}
										type="content"
										title="Mobile Rectangle 1"
									/>
								),
								3: (
									<AdItem
										mobileAdId={4403481}
										maxHeight={250}
										type="content"
										title="Mobile Rectangle 1"
									/>
								),
							}}
						/>

					</AppMainCol>

					<AppSideCol boxed={false}>
						<div>
							<ProductPromotionListWidgetContainer
								widgetKey={`vendordetail-product-promotion-list-${vendorSlug}`}
								title={`Beliebte Aktionen bei ${vendorName}`}
								actionButtonLabel={`Alle Aktionen bei ${vendorName}`}
								actionButtonRouteCallbk={this.routerCallbkPromotionListWidget}
								limit={10}
								orderBy="createdAt"
								orderDir="desc"
								fetchAssortementProductPromotions={true}
								fetchPriceProductPromotion={true}
								fetchProductPromotionGridGroupeds={true}
								vendorSlug={vendorSlug}
							/>

							<PromotionsAggregatedByProductGroupsWidgetContainer
								widgetKey={`${vendorName}-product-groups-widget`}
								limit={10}
								fetchProductPromotionGridGroupeds={true}
								title={`Beliebte Produktkategorien bei ${vendorName}`}
								routeCallbk={this.routerCallbkProductGroupListWidget}
								vendorSlug={vendorSlug}
							/>

							<AdItem
								desktopAdId={4403494}
								maxHeight={250}
								maxWidth={300}
								type="content"
								title="Medium Rectangle 1"
							/>

							<PromotionsAggregatedByBrandsWidgetContainer
								widgetKey={`vendordetail-brands-${vendorSlug}`}
								title={`Marken in Aktion bei ${vendorName}`}
								limit={10}
								routeCallbk={this.routerCallbkBrandListWidget}
								vendorSlug={vendorSlug}
							/>

							<StoryListWidgetContainer
								title={`Stories`}
								widgetKey={`vendor-detail-stories-${vendorSlug}`}
								moreLabel="Alle Stories"
								limit={3}
								vendorSlug={vendorSlug}
							/>

							<MobileAppWidget />
						</div>
					</AppSideCol>
				</Row>
			</PageWrapper>
		);
	}

	/**
   * Takes data from our main list and aggregates them to count grouped by state
   */
	private getStateArrayWithStoreCount() {
		// get shop listing data
		const shopList = this.getShopListGroupData();

		if (!shopList) {
			return [];
		}

		// create the array
		const retArr = [];

		for (const state of shopList) {
			retArr.push({
				label: state.group.name,
				slug: state.group.slug,
				count: state.totalItemsCount
			});
		}

		return retArr;
	}

	/**
   * Get shoplisting data, that is fetched a level deeper, but is needed for state switcher aggregation
   */
	private getShopListGroupData() {
		const { vendorSlug, shopGroupedByStateReducer } = this.props;

		if (!shopGroupedByStateReducer || !shopGroupedByStateReducer.queries) {
			return null;
		}

		const queryKey = `shopGroupedByState-vendor-detail-shop-list-${vendorSlug}-${DataStateService.getStateKey()}`;

		if (shopGroupedByStateReducer.queries[queryKey]) {
			return shopGroupedByStateReducer.queries[queryKey];
		}

		return null;
	}

	// TRANSLATE
	/**
   * Returns the breadcrumb path for this page
   */
	private getBreadcrumbs() {
		const { vendor } = this.props;

		return [
			{
				label: 'Händler Übersicht',
				route: 'VendorListingRoute'
			} as IBreadcrumbItemType,
			{
				label: vendor ? vendor.name : 'Händler'
			} as IBreadcrumbItemType
		];
	}

	/**
   * Defines the route the product group list widget should be routed towards
   *
   * @param item
   */
	private routerCallbkProductGroupListWidget = (item: IPromotionsAggregatedByProductGroupType) => {
		const { vendorSlug } = this.props;

		return {
			name: 'PromotionsInProductGroupAndVendorRoute',
			params: {
				productGroup: item.group.slug,
				vendor: vendorSlug
			}
		};
	};

	/**
   * Defines the route the promotion list widget should be routed towards
   */
	private routerCallbkPromotionListWidget = () => {
		const { vendorSlug } = this.props;

		return {
			name: 'PromotionsByVendorRoute',
			params: {
				vendor: vendorSlug
			}
		};
	};

	/**
   * Defines the route the state list widget should be routed towards
   *
   * @param item
   */
	private routerCallbkBrandListWidget = (item: IPromotionsAggregatedByBrandType) => {
		const { vendorSlug } = this.props;

		return {
			name: 'PromotionsFromBrandAndVendorRoute',
			params: {
				brand: item.group.slug,
				vendor: vendorSlug
			}
		};
	};

	/**
   * Defines the route the shop list widget should be routed towards
   *
   * @param item
   */
	private routerCallbkShopListWidget = (item: IShopGroupedByStateType) => {
		const { vendorSlug } = this.props;

		return {
			name: 'ShopsByVendorAndStateRoute',
			params: {
				state: item.group.slug,
				vendor: vendorSlug
			}
		};
	};
}

export default VendorDetail;
